<template>
  <!-- {{ store.settings }} -->
  <div class="row">
    <div class="col border">
      <ul class="list-group">
        <li
          class="list-group-item d-flex justify-content-between"
          v-for="(s, index) in sortedStages"
          :key="s.id"
          :class="getColour(s.id)"
        >
          {{ index + 1 }}. {{ s.name }}
          <div class="dropdown">
            <button
              class="btn btn-sm border rounded dropdown-toggle"
              type="button"
              id="dropdownMenu2"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Select
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
              <li class="border rounded">
                <button
                  class="dropdown-item"
                  type="button"
                  style="background-color: #defde0; color: black"
                  @click="setColour('defde0', s.id)"
                >
                  Green
                </button>
              </li>
              <li class="border rounded">
                <button
                  class="dropdown-item"
                  type="button"
                  style="background-color: #def3fd; color: black"
                  @click="setColour('def3fd', s.id)"
                >
                  Blue
                </button>
              </li>
              <li class="border rounded">
                <button
                  class="dropdown-item"
                  type="button"
                  style="background-color: #fddfdf; color: black"
                  @click="setColour('fddfdf', s.id)"
                >
                  Red
                </button>
              </li>
              <li class="border rounded">
                <button
                  class="dropdown-item"
                  type="button"
                  style="background-color: #fcf7de; color: black"
                  @click="setColour('fcf7de', s.id)"
                >
                  Yellow
                </button>
              </li>
              <li class="border rounded">
                <button
                  class="dropdown-item"
                  type="button"
                  @click="clearColour(s.id)"
                >
                  Clear
                </button>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <div class="col border">
      <ul class="list-group">
        <li
          class="list-group-item d-flex justify-content-between"
          v-for="(s, index) in sortedStages"
          :key="s.id"
        >
          {{ index + 1 }}. {{ s.name }}
          <span
            class="badge rounded-pill bg-primary"
            v-if="getPriority('one', s.id)"
            >1</span
          >
          <span
            class="badge rounded-pill bg-primary"
            v-if="getPriority('two', s.id)"
            >2</span
          >
          <span
            class="badge rounded-pill bg-secondary"
            v-if="getPriority('hide', s.id)"
            >Hide</span
          >
          <div class="dropdown">
            <button
              class="btn btn-sm border rounded dropdown-toggle"
              type="button"
              id="dropdownMenu2"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Select
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
              <li class="border rounded">
                <button
                  class="dropdown-item"
                  type="button"
                  @click="setPriority('one', s.id)"
                >
                  Priority 1
                </button>
              </li>
              <!-- <li class="border rounded">
                <button
                  class="dropdown-item"
                  type="button"
                  @click="setPriority('two', s.id)"
                >
                  Priority 2
                </button>
              </li> -->
              <li class="border rounded">
                <button
                  class="dropdown-item bg-secondary"
                  type="button"
                  @click="setPriority('hide', s.id)"
                >
                  Hide
                </button>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <div class="col border">
      <div class="row mt-1">
        <div class="col">
          Lead phone numbers:
          <button
            class="btn btn-sm btn-danger float-end"
            @click="togglePhoneVisibility()"
            v-if="allSettings.showphone"
          >
            Visible
          </button>
          <button
            class="btn btn-sm btn-primary float-end"
            @click="togglePhoneVisibility()"
            v-if="!allSettings.showphone"
          >
            Hidden
          </button>
        </div>
      </div>
      <div class="row mt-1">
        <input
          type="text"
          placeholder="New tag..."
          class="form-control col"
          v-model="tag"
        />
        <button class="btn btn-primary btn-sm col mx-1" @click="addTag()">
          Add
        </button>
      </div>

      <button
        class="btn btn-primary btn-sm mx-1 mt-1"
        v-for="t in allSettings.tags"
        :key="t"
        @click="selectTag(t)"
      >
        {{ t }}
        <i
          class="fa fa-trash-o"
          v-if="tagToDelete == t"
          @click="deleteTag()"
        ></i>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col border">
      <div class="form-group">
        <label for="exampleFormControlTextarea1"
          >Notes (seperate notes by ;;)</label
        >

        <textarea
          class="form-control"
          id="exampleFormControlTextarea1"
          rows="3"
          v-model="notes"
        ></textarea>
        <button
          class="btn btn-primary btn-sm"
          v-if="showUpdateNotesButton"
          @click="updateNotes()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useStore } from "../../store/index";
import {
  collection,
  addDoc,
  serverTimestamp,
  query,
  where,
  onSnapshot,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import { computed, onBeforeUnmount, onMounted, ref, watchEffect } from "vue";
const allStages = ref([]);
const store = useStore();
const allSettings = ref({});
const tag = ref("");
const notes = ref("");
const tagToDelete = ref("");
let unsub = () => {};
let unsubscribe = () => {};
const showUpdateNotesButton = computed(() => {
  var returnValue = false;
  if (allSettings.value.notes != notes.value) returnValue = true;
  return returnValue;
});
onBeforeUnmount(() => {
  unsub();
  unsubscribe();
});
onMounted(() => {
  const q = query(collection(db, "stages"), where("admin", "==", store.admin));
  unsub = onSnapshot(q, (querySnapshot) => {
    const cities = [];
    querySnapshot.forEach((docy) => {
      cities.push({ id: docy.id, ...docy.data() });
    });
    allStages.value = cities;
  });
  const k = query(
    collection(db, "settings"),
    where("admin", "==", store.admin)
  );
  unsubscribe = onSnapshot(k, (querySnapshot) => {
    // const cities = [];
    querySnapshot.forEach((docy) => {
      //cities.push({ id: docy.id, ...docy.data() });
      allSettings.value = { id: docy.id, ...docy.data() };
      notes.value = allSettings.value.notes;
    });
    //allSettings.value = cities;
  });
});
const sortedStages = computed(() => {
  const arr = [];
  let next_id;
  const first = allStages.value.filter((st) => st.next == "end");
  if (first[0]) {
    //find last stage
    arr.unshift(first[0]);
    next_id = first[0].id;
  }
  for (let i = 0; i < allStages.value.length; i++) {
    allStages.value.forEach((as) => {
      if (as.next == next_id && !arr.includes(as)) {
        arr.unshift(as);
        next_id = as.id;
      }
    });
  }
  return arr;
});
const setColour = async (colour, stage) => {
  let existingColor = ""; //first checking if this stage already has nother colour, if yes, then it is set to clear
  if (store.settings.colours.defde0 == stage) {
    existingColor = "defde0";
  }
  if (store.settings.colours.def3fd == stage) {
    existingColor = "def3fd";
  }
  if (store.settings.colours.fddfdf == stage) {
    existingColor = "fddfdf";
  }
  if (store.settings.colours.fcf7de == stage) {
    existingColor = "fcf7de";
  }

  const washingtonRef = doc(db, "settings", store.settings.id);
  if (existingColor.length) {
    await updateDoc(washingtonRef, {
      ["colours." + existingColor]: "",
    });
  }
  await updateDoc(washingtonRef, {
    ["colours." + colour]: stage,
  });
};
const clearColour = async (stage) => {
  let existingColor = "";
  if (store.settings.colours.defde0 == stage) {
    existingColor = "defde0";
  }
  if (store.settings.colours.def3fd == stage) {
    existingColor = "def3fd";
  }
  if (store.settings.colours.fddfdf == stage) {
    existingColor = "fddfdf";
  }
  if (store.settings.colours.fcf7de == stage) {
    existingColor = "fcf7de";
  }
  const washingtonRef = doc(db, "settings", store.settings.id);
  await updateDoc(washingtonRef, {
    ["colours." + existingColor]: "",
  });
};
const getColour = (stage) => {
  let returnClass = "";
  if (store.settings.colours.defde0 == stage) {
    returnClass = "bggreen";
  }
  if (store.settings.colours.def3fd == stage) {
    returnClass = "bgblue";
  }
  if (store.settings.colours.fddfdf == stage) {
    returnClass = "bgred";
  }
  if (store.settings.colours.fcf7de == stage) {
    returnClass = "bgyellow";
  }
  return returnClass;
};
const setPriority = async (prior, stage) => {
  let existingPriority = ""; //first checking if this stage already has nother colour, if yes, then it is set to clear
  if (store.settings.priority.one == stage) {
    existingPriority = "one";
  }
  if (store.settings.priority.two == stage) {
    existingPriority = "two";
  }
  if (store.settings.priority.hide == stage) {
    existingPriority = "hide";
  }

  const washingtonRef = doc(db, "settings", store.settings.id);
  if (existingPriority.length) {
    await updateDoc(washingtonRef, {
      ["priority." + existingPriority]: "",
    });
  }
  await updateDoc(washingtonRef, {
    ["priority." + prior]: stage,
  });
};
const getPriority = (prior, stage) => {
  if (store.settings.priority[prior] == stage) return true;
};
const addTag = async () => {
  //  console.log(tag.value);
  const washingtonRef = doc(db, "settings", store.settings.id);
  await updateDoc(washingtonRef, {
    tags: arrayUnion(tag.value),
  });
  tag.value = "";
};
const updateNotes = async () => {
  //  console.log(tag.value);
  const washingtonRef = doc(db, "settings", store.settings.id);
  await updateDoc(washingtonRef, {
    notes: notes.value,
  });
};
const deleteTag = async () => {
  const washingtonRef = doc(db, "settings", store.settings.id);
  await updateDoc(washingtonRef, {
    tags: arrayRemove(tagToDelete.value),
  });
  tagToDelete.value = "";
};
const selectTag = (id) => {
  if (tagToDelete.value != id) {
    tagToDelete.value = id;
  } else {
    tagToDelete.value = "";
  }
};
const togglePhoneVisibility = async () => {
  const k = doc(db, "settings", store.settings.id);
  await updateDoc(k, {
    ["showphone"]: !store.settings.showphone,
  });
};
</script>
<style class>
.bggreen {
  background-color: #defde0;
}
.bgblue {
  background-color: #def3fd;
}
.bgred {
  background-color: #fddfdf;
}
.bgyellow {
  background-color: #fcf7de;
}
</style>
