<template>
  <div class="row">
    <input
      type="date"
      class="form-control reminder mx-1 my-1"
      v-model.trim="date"
      @change="setReminder()"
    />
  </div>
  <div class="d-flex justify-content-around mt-1">
    <span
      class="badge rounded-pill bg-primary tinytxt"
      @click="setReminder('tomorrow')"
    >
      1d
    </span>
    <span
      class="badge rounded-pill bg-primary tinytxt"
      @click="setReminder('week')"
    >
      1w
    </span>
    <span
      class="badge rounded-pill bg-primary tinytxt"
      @click="setReminder('month')"
    >
      1m
    </span>
  </div>
  <div class="row border rounded mt-1" v-if="reminderDate.length">
    <div class="col" @click="selectToDeleteReminder(reminderDate)">
      {{ reminderDate }}
    </div>
    <div class="col">
      <i
        class="fa fa-trash-o float-start"
        style="font-size: 12px"
        v-if="deleteReminder"
        @click="reminderDelete()"
      ></i>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps, computed } from "vue";
import {
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  deleteDoc,
  addDoc,
  query,
  collection,
  getDocs,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import { useStore } from "../../store/index";
const store = useStore();
const dayjs = require("dayjs");
const props = defineProps(["data", "project"]);
const date = ref("");
const deleteReminder = ref(false);
const reminderDate = computed(() => {
  var re = "";
  props.data.reminders.forEach((r) => {
    if (r.project == props.project) {
      re = r.date;
    }
  });
  return re;
});
const setReminder = async (str) => {
  const today = new Date();
  const leadRef = doc(db, "leads", props.data.id);
  if (str == "tomorrow") {
    date.value = dayjs(today).add(1, "day").format("YYYY-MM-DD");
  }
  if (str == "week") {
    date.value = dayjs(today).add(1, "week").format("YYYY-MM-DD");
  }
  if (str == "month") {
    date.value = dayjs(today).add(1, "month").format("YYYY-MM-DD");
  }
  //console.log("set reminder for =>", date.value);
  //console.log(props.data.id, props.project);
  const reminderObj = {
    project: props.project,
    date: date.value,
  };
  props.data.reminders.forEach(async (r) => {
    if (r.project == props.project) {
      //only one reminder per project allowed
      await updateDoc(leadRef, {
        reminders: arrayRemove(r),
      });
      // const q = query(
      //   collection(db, "reminders"),
      //   where("leadid", "==", props.data.id),
      //   where("project", "==", props.project),
      //   where("userid", "==", store.userid)
      // );
      // const querySnapshot = await getDocs(q);
      // querySnapshot.forEach(async (docy) => {
      //   // doc.data() is never undefined for query doc snapshots
      //   //console.log(doc.id, " => ", doc.data());
      //   await deleteDoc(docy.id);
      // });
    }
  });
  await updateDoc(leadRef, {
    reminders: arrayUnion(reminderObj),
  });
  await addDoc(collection(db, "reminders"), {
    ...reminderObj,
    leadid: props.data.id,
    userid: store.userid,
  });
  date.value = "";
};
const selectToDeleteReminder = () => {
  deleteReminder.value = !deleteReminder.value;
};
const reminderDelete = async () => {
  const leadRef = doc(db, "leads", props.data.id);
  props.data.reminders.forEach(async (r) => {
    if (r.project == props.project) {
      //only one reminder per project allowed
      await updateDoc(leadRef, {
        reminders: arrayRemove(r),
      });
    }
  });
  const q = query(
    collection(db, "reminders"),
    where("leadid", "==", props.data.id),
    where("userid", "==", store.userid)
  );
  //await deleteDoc(q);
  const querySnapshot = await getDocs(q);
  const reminder_to_delete = [];
  querySnapshot.forEach(async (docy) => {
    // doc.data() is never undefined for query doc snapshots
    //console.log(docy.id, " => ", docy.data());
    //await deleteDoc(docy.id);
    reminder_to_delete.push(docy.id);
  });
  if (reminder_to_delete.length) {
    reminder_to_delete.forEach(async (rd) => {
      await deleteDoc(doc(db, "reminders", rd));
    });
  }

  deleteReminder.value = false;
};
</script>
<style scoped>
.reminder {
  padding: 1px;
  font-size: 10px;
}
</style>
