<template>
  <div>
    <!-- <h6>addLocation:</h6>
    {{ props.addLocation }},
    <h6>baseStage:</h6>
    {{ props.baseStage }} -->
    <input type="text" class="form-control" v-model.trim="name" />
    <button class="btn btn-sm btn-success" @click="addStage()">Add</button>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watchEffect } from "vue";
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  serverTimestamp,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase/config";
const props = defineProps(["baseStage", "admin", "addLocation"]);

const name = ref("");
let newlyAdded = null;

const addStage = async () => {
  if (name.value.length) {
    const stageObj = {
      admin: props.admin,
      name: name.value,
    };
    if (props.addLocation == "after") {
      stageObj.next = props.baseStage.next;
      newlyAdded = await addDoc(collection(db, "stages"), stageObj);
      const bStage = doc(db, "stages", props.baseStage.id);
      await updateDoc(bStage, {
        next: newlyAdded.id,
      });
    }
    if (props.addLocation == "before") {
      stageObj.next = props.baseStage.id;
      newlyAdded = await addDoc(collection(db, "stages"), stageObj);
      //   const bStage = doc(db, "stages", props.baseStage.id);
      //   await updateDoc(bStage, {
      //     next: newlyAdded.id,
      //   });
    }
  }

  //   if (name.value.length) {
  //     //console.log(stageObj);
  //     newlyAdded = await addDoc(collection(db, "stages"), stageObj);
  //     console.log("before");
  //     console.log("Document written with ID: ", newlyAdded.id);
  //     console.log("after");
  //     setPrevNext(newlyAdded.id, props.next);
  //   }
};

// watchEffect(() => {
//   if (newlyAdded) {
//     setPrevNext(newlyAdded.id, props.prev);
//     setNextPrev(newlyAdded.id, props.next);
//   }
// });
const setPrevNext = async (newlyAdded, prev) => {
  const washingtonRef = doc(db, "stages", prev);
  console.log("setPrevNext called");
  await updateDoc(washingtonRef, {
    prev: newlyAdded,
  });
};
</script>
