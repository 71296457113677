<template>
  <div class="row" v-if="error.length">
    <ul class="list-group">
      <li
        class="list-group-item bg-warning border rounded midtxt"
        v-for="e in error"
        :key="e"
      >
        {{ e }}
      </li>
    </ul>
  </div>
  <div class="row border border-2 border-secondary rounded mt-2 mb-2">
    <div class="col">
      <button
        class="btn btn-sm btn-outline-primary dropdown-toggle"
        type="button"
        id="dropdownMenu2"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span v-if="!selectedStage">Stages</span>
        <span v-else>{{ getStageName(selectedStage) }}</span>
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
        <li
          v-for="st in sortedStages"
          :key="st.id"
          class="dropdown-item midtxt border-top"
          :class="getStageColour(st.id)"
          @click="selectStage(st.id)"
        >
          {{ st.name }}
        </li>
      </ul>
    </div>
    <div class="col">
      <button
        class="btn btn-sm btn-outline-secondary dropdown-toggle"
        type="button"
        id="dropdownMenu2"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span v-if="!selectedTag">Tag</span>
        <span v-else>{{ selectedTag }}</span>
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
        <li
          v-for="t in store.settings.tags"
          :key="t"
          class="dropdown-item midtxt border-top"
          @click="selectTag(t)"
        >
          {{ t }}
        </li>
      </ul>
    </div>
    <div class="col">
      <input
        type="text"
        class="form-control"
        placeholder="Name"
        v-model.trim="name"
      />
    </div>
    <div class="col">
      <input
        type="text"
        class="form-control"
        placeholder="Phone"
        @keyup="verifyPhone()"
        v-model.trim="phone"
      />
    </div>
    <div class="col">
      <input
        type="text"
        class="form-control"
        placeholder="Email (optional)"
        v-model.trim="email"
      />
    </div>
    <div class="col">
      <button class="btn btn-primary" @click="addLead()">Add</button>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, defineProps, defineEmits } from "vue";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db, auth } from "../../firebase/config";
import { useStore } from "../../store/index";
const props = defineProps(["leads", "project"]);
const emit = defineEmits(["leadAdded"]);
const store = useStore();
const name = ref("");
const phone = ref("");
const email = ref("");
const allStages = ref(store.stages);
const selectedStage = ref("");
const selectedTag = ref("");
const error = ref([]);
function removeNonDigitsAndCommas(str) {
  return str.replace(/[^\d,]/g, "");
}

const checkIfPhoneNumberExists = (arr) => {
  error.value = [];
  const existingPhones = [];
  props.leads.forEach((l) => {
    l.phone.forEach((p) => {
      existingPhones.push(p);
    });
  });
  arr.forEach((pp) => {
    existingPhones.forEach((ep) => {
      if (ep == pp) {
        if (!error.value.includes("Phone " + pp + " already exists"))
          error.value.push("Phone " + pp + " already exists");
      }
    });
  });
};
const verifyPhone = () => {
  phone.value = removeNonDigitsAndCommas(phone.value);
  var ph = [];
  ph = phone.value.split(",");
  checkIfPhoneNumberExists(ph);
};
const checkForErrors = () => {
  error.value = [];
  if (!selectedStage.value) {
    error.value.push("Stage not selected");
  }
  if (!name.value) {
    error.value.push("Please enter name");
  }
  if (!phone.value) {
    error.value.push("Please enter phone");
  }
};
const clearData = () => {
  name.value = "";
  phone.value = "";
  email.value = "";
  selectedStage.value = "";
  selectedTag.value = "";
};
const addLead = async () => {
  checkForErrors();
  var leadObj = {
    admin: store.admin,
    email: email.value,
    updated: serverTimestamp(),
    created: serverTimestamp(),
    name: name.value,
    phone: phone.value.split(","),
    priority: false, //priority of lead
    //stages: { ["projectID_" + randomProject]: randomStage }, // where in the sales process (stage) is he
    //projects: [randomProject], //which properties/projects is this lead interested in - can be blank too
    stages: selectedStage.value,
    projects: props.project,
    comments: [], //comments made by users
    reminders: [], //
    activitylog: [], //record of all activities done on this lead
    //users: randomUser, //which users can access this
    users: [auth.currentUser.uid], //this is done so that one lead can be shared across multiple users
    source: [], //where did this lead come from - reference/magicbricks/olx etc
    tags: [selectedTag.value], //searchable and sortable tags
    type: ["buyer"], //buyer/seller
    interested: "yes", //yes, no, maybe
  };
  console.log(leadObj);
  const docRef = await addDoc(collection(db, "leads"), leadObj);
  if (docRef.id) {
    emit("leadAdded");
    clearData();
  }
};

const selectStage = (id) => {
  if (selectedStage.value != id) {
    selectedStage.value = id;
  } else {
    selectedStage.value = "";
  }
};
const selectTag = (t) => {
  if (selectedTag.value != t) {
    selectedTag.value = t;
  } else {
    selectedTag.value = "";
  }
};
const getStageName = (id) => {
  var name = "";
  sortedStages.value.forEach((s) => {
    if (s.id == id) {
      name = s.name;
    }
  });
  return name;
};
const getStageColour = (stage) => {
  // const stage = props.data.stages["projectID_" + props.project];
  let returnClass = "";
  if (store.settings.colours.defde0 == stage) {
    returnClass = "bggreen";
  }
  if (store.settings.colours.def3fd == stage) {
    returnClass = "bgblue";
  }
  if (store.settings.colours.fddfdf == stage) {
    returnClass = "bgred";
  }
  if (store.settings.colours.fcf7de == stage) {
    returnClass = "bgyellow";
  }
  return returnClass;
};
const sortedStages = computed(() => {
  const arr = [];
  let next_id;
  const first = allStages.value.filter((st) => st.next == "end");
  if (first[0]) {
    //find last stage
    arr.unshift(first[0]);
    next_id = first[0].id;
  }
  for (let i = 0; i < allStages.value.length; i++) {
    allStages.value.forEach((as) => {
      if (as.next == next_id && !arr.includes(as)) {
        arr.unshift(as);
        next_id = as.id;
      }
    });
  }
  return arr;
});
// const dummyLead = async () => {
//   const randomProject = getRandomProject();
//   const randomStage = getRandomStage();
//   const randomUser = getRandomUser();
//   const leadObj = {
//     admin: store.admin,
//     email: faker.internet.exampleEmail(),
//     updated: serverTimestamp(),
//     created: serverTimestamp(),
//     name: faker.person.fullName(),
//     phone: [nanoid()],
//     priority: false, //priority of lead
//     //stages: { ["projectID_" + randomProject]: randomStage }, // where in the sales process (stage) is he
//     //projects: [randomProject], //which properties/projects is this lead interested in - can be blank too
//     stages: randomStage,
//     projects: randomProject,
//     comments: [], //comments made by users
//     reminders: [], //
//     activitylog: [], //record of all activities done on this lead
//     //users: randomUser, //which users can access this
//     users: ["iB9eDctzicREnMaWApKqkVY2VtI2"], //this is done so that one lead can be shared across multiple users
//     source: [], //where did this lead come from - reference/magicbricks/olx etc
//     tags: [], //searchable and sortable tags
//     type: ["buyer"], //buyer/seller
//     interested: "yes", //yes, no, maybe
//   };
//   //console.log(leadObj);
//   const docRef = await addDoc(collection(db, "leads"), leadObj);
//   console.log("Document written with ID: ", docRef.id);
// };
</script>
<style scoped>
.list-group-item {
  padding: 3px;
  font-size: 10px;
}
.bggreen {
  background-color: #defde0;
}
.bgblue {
  background-color: #def3fd;
}
.bgred {
  background-color: #fddfdf;
}
.bgyellow {
  background-color: #fcf7de;
}
.smalltxt {
  font-size: 10px;
}
.midtxt {
  font-size: 12px;
}
.btn-sm {
  padding: 1px;
  font-size: 10px;
}
</style>
