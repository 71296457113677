import { defineStore } from "pinia";

export const useStore = defineStore("store", {
  state: () => ({
    userid: "",
    admin: "",
    type: "",
    expandLead: null,
    visibility: [],
    editproject: "",
    stages: [],
    settings: {},
    display: {
      tags: [],
      alarm: false,
      stages: [],
      today: false, //show or hide leads that were updated today
    },
    usersettings: {},
  }),
  getters: {
    //double: (state) => state.count * 2,
  },
  actions: {
    setDisplay(obj) {
      this.display = { ...obj };
    },
    setUserSettings(obj) {
      this.usersettings = { ...obj };
    },
    setSettings(obj) {
      this.settings = { ...obj };
    },
    setEditProject(id) {
      this.editproject = id;
    },
    clearEditProject() {
      this.editproject = "";
    },
    addStage(id) {
      this.stages.push(id);
    },
    clearStages() {
      this.stages = [];
    },
    setUser(data) {
      console.log("Instore");
      this.userid = data.userid;
      this.admin = data.admin;
      this.type = data.type;
    },
    clearUser() {
      this.userid = "";
      this.type = "";
      this.admin = "";
    },
    setExpandLead(id) {
      this.expandLead = id;
    },
    setVisibility(view) {
      if (!this.visibility.includes(view)) {
        this.visibility.push(view);
      }
    },
    clearVisibility(view) {
      this.visibility = this.visibility.filter((item) => item !== view);
    },
  },
});
