<template>
  <ul class="list-group">
    <li
      class="list-group-item smalltxt nopadding"
      v-for="a in sortedLog"
      :key="a"
    >
      {{ a.date }} {{ dataFormat(a) }}
    </li>
  </ul>
</template>
<script setup>
import { defineProps, computed } from "vue";
import { useStore } from "../../store/index";
const store = useStore();
const props = defineProps(["data"]);
const dataFormat = (item) => {
  var name = "";
  if (item.type == "stage") {
    //console.log(item, store.stages);
    store.stages.forEach((i) => {
      if (i.id == item.data) {
        name = i.name;
      }
    });
  } else {
    name = item.data;
  }
  return name;
};
const sortedLog = computed(() => {
  const arr = [];
  props.data.forEach((i) => arr.push(i));
  arr.sort((a, b) => {
    if (a.date < b.date) {
      return 1;
    }
    if (a.date > b.date) {
      return -1;
    }

    // names must be equal
    return 0;
  });
  return arr;
});
</script>
<style scoped>
.smalltxt {
  padding: 2px;
}
.reminder {
  padding: 1px;
  font-size: 10px;
}
.nopadding {
  padding: 2px;
}
</style>
