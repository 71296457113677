<template>
  <!-- StagesHome {{ store.admin }} AllStages: {{ allStages.length }},
  SortedStages:{{ sortedStages.length }} {{ sortedStages }} -->
  <div v-if="!allStages.length">
    <input
      type="text"
      class="form-control"
      placeholder="First stage"
      v-model.trim="name"
    />
    <button class="btn btn-primary btn-sm" @click="startProcess()">Add</button>
  </div>
  <ul class="list-group" v-if="sortedStages.length">
    <li class="list-group-item" v-for="s in sortedStages" :key="s.id">
      <div class="row">
        <div class="col-1">
          <button class="btn btn-sm btn-success" @click="addBefore(s.id)">
            <i class="bi bi-arrow-up"></i>
          </button>
        </div>
        <div class="col">
          <NewStage
            :addLocation="addLocation"
            :baseStage="s"
            :admin="store.admin"
            v-if="add_before == s.id"
          ></NewStage>
          {{ s.name }}
          <!-- ({{ s.id }})--{{ s.name }} -->
          <!-- <h6>Next:</h6>
          {{ s.next }} -->
          <NewStage
            :addLocation="addLocation"
            :baseStage="s"
            :admin="store.admin"
            v-if="add_after == s.id"
          ></NewStage>
        </div>
        <div class="col-1">
          <button
            class="btn btn-sm btn-success float-end"
            @click="addAfter(s.id)"
          >
            <i class="bi bi-arrow-down"></i>
          </button>
        </div>
      </div>
    </li>
  </ul>
</template>
<script setup>
import { useStore } from "../../store/index";
import NewStage from "./NewStage.vue";
import {
  collection,
  addDoc,
  serverTimestamp,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import { computed, onBeforeUnmount, onMounted, ref, watchEffect } from "vue";
const store = useStore();
const allStages = ref([]);
//const sortedStages = ref([]);
const name = ref("");
const add_before = ref("");
const add_after = ref("");
const addLocation = ref("");
let unsub;
onBeforeUnmount(() => {
  unsub();
});
onMounted(() => {
  const q = query(collection(db, "stages"), where("admin", "==", store.admin));
  unsub = onSnapshot(q, (querySnapshot) => {
    const cities = [];
    querySnapshot.forEach((doc) => {
      cities.push({ id: doc.id, ...doc.data() });
    });
    allStages.value = cities;
  });
});

const addBefore = (id) => {
  addLocation.value = "before";
  if (add_before.value != id) {
    add_before.value = id;
  } else {
    add_before.value = "";
  }
  add_after.value = "";
};
const addAfter = (id) => {
  addLocation.value = "after";
  if (add_after.value != id) {
    add_after.value = id;
  } else {
    add_after.value = "";
  }
  add_before.value = "";
};
const sortedStages = computed(() => {
  const arr = [];
  let next_id;
  const first = allStages.value.filter((st) => st.next == "end");
  if (first[0]) {
    //find last stage
    arr.unshift(first[0]);
    next_id = first[0].id;
  }
  for (let i = 0; i < allStages.value.length; i++) {
    allStages.value.forEach((as) => {
      if (as.next == next_id && !arr.includes(as)) {
        arr.unshift(as);
        next_id = as.id;
      }
    });
  }
  return arr;
});
const startProcess = async () => {
  // Add a new document with a generated id.
  const docRef = await addDoc(collection(db, "stages"), {
    name: name.value,
    admin: store.admin,

    next: "end",
  });
  console.log("Document written with ID: ", docRef.id);
};
</script>
