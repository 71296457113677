<template>
  <div class="row mb-1" v-if="reminders.length">
    <div class="col">
      Pending reminders:
      <span class="badge rounded-pill bg-primary" @click="toggleList()">{{
        reminders.length
      }}</span>
    </div>
  </div>
  <div class="row d-flex justify-content-start mt-1 mb-1" v-if="shownames">
    <!-- <div v-for="r in reminders" :key="r.id" class="border">{{ r.id }}</div> -->

    <div v-if="!reminders.length">
      <div class="col">No pending reminders</div>
    </div>
    <div class="row" v-else>
      <div
        class="card border smlcard mx-1"
        style="width: 15rem"
        v-for="r in reminders"
        :key="r.id"
      >
        <ReminderCard :leadid="r.leadid" :reminderid="r.id"></ReminderCard>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onBeforeMount, onBeforeUnmount, ref } from "vue";
import { useStore } from "../../store/index";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import ReminderCard from "./ReminderCard.vue";
import { db } from "../../firebase/config";
const reminders = ref([]);
const dayjs = require("dayjs");
const t = new Date();
const today = ref(dayjs(t).format("YYYY-MM-DD"));
const store = useStore();
const shownames = ref(false);
const toggleList = () => {
  shownames.value = !shownames.value;
};
var unsubscribe = () => {};
onBeforeUnmount(() => {
  unsubscribe();
});
onBeforeMount(() => {
  const q = query(
    collection(db, "reminders"),
    where("userid", "==", store.userid),
    where("date", "<=", today.value)
  );
  unsubscribe = onSnapshot(q, (querySnapshot) => {
    const cities = [];
    querySnapshot.forEach((docy) => {
      cities.push({ id: docy.id, ...docy.data() });
    });
    reminders.value = cities;
  });
});
</script>
<style scoped>
.card-body {
  padding: 2px;
}
.smlcard {
  padding: 1px;
  font-size: 12px;
}
.icon-top-right {
  float: right;
  position: relative;
  vertical-align: top;
  right: 0;
}
</style>
