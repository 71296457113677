<template>
  <div class="row smalltxt border rounded">
    <div class="col">
      <!-- <div v-if="!toggleName" @click="toggleName = !toggleName">
        {{ props.data.name }}
      </div>
      <div v-if="toggleName">
        <div class="input-group mb-1 mt-1 smalltxt">
          <input
            type="text"
            class="form-control reminder"
            v-model.trim="name"
            aria-describedby="button-addon3"
          />
          <button
            class="btn btn-outline-secondary reminder"
            type="button"
            id="button-addon3"
            @click="updateName()"
          >
            Update
          </button>
        </div>
      </div> -->
      <NamePhoneComponent :data="props.data"></NamePhoneComponent>
    </div>
    <div class="col">
      <ActivityLogComponent
        :data="props.data.activitylog"
      ></ActivityLogComponent>
    </div>
    <div class="col">
      <ReminderComponent
        :data="props.data"
        :project="props.project"
      ></ReminderComponent>
    </div>
    <div class="col">
      <div class="input-group mb-1 mt-1 smalltxt">
        <input
          type="text"
          class="form-control reminder"
          placeholder="Comment"
          v-model.trim="comment"
          aria-describedby="button-addon2"
        />
        <button
          class="btn btn-outline-secondary reminder"
          type="button"
          id="button-addon2"
          @click="addComment()"
        >
          Add
        </button>
      </div>
      <span v-for="tg in store.usersettings.notes" :key="tg">
        <span
          class="badge rounded-pill bg-secondary tinytxt"
          @click="selectComment(tg)"
        >
          {{ tg }}
        </span>
      </span>
    </div>
    <div class="col">
      <!-- {{ props.data.comments }} -->
      <i class="fa fa-close ms-1 muted float-end" @click="toggleDetails()"></i>
      <ul class="list-group">
        <li
          class="list-group-item smalltxt"
          v-for="c in props.data.comments"
          :key="c.id"
          @click="selectToDelete(c.id)"
        >
          {{ c.date }}: {{ c.content }}
          <i
            class="fa fa-trash-o float-end"
            v-if="c.id == deleteid"
            @click="deleteComment(c)"
          ></i>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
import { computed, defineProps, ref } from "vue";
import { db, auth } from "../../firebase/config";
import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import dayjs from "dayjs";
import { useStore } from "../../store/index";
import ReminderComponent from "./ReminderComponent.vue";
import NamePhoneComponent from "./NamePhoneComponent.vue";
import ActivityLogComponent from "./ActivityLogComponent.vue";
const { customAlphabet } = require("nanoid");
const alphabet =
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
const nanoid = customAlphabet(alphabet, 6);
const today = dayjs().format("DD-MM-YYYY HH:mm");
const store = useStore();
const props = defineProps(["data", "project"]);
const comment = ref("");
const deleteid = ref("");
const name = computed(() => {
  var n = "";
  if (toggleName.value) {
    n = props.data.name;
  } else {
    n = "";
  }
  return n;
});
const toggleName = ref(false);
const selectToDelete = (id) => {
  if (deleteid.value != id) {
    deleteid.value = id;
  } else {
    deleteid.value = "";
  }
};
const selectComment = (c) => {
  comment.value = c;
};
const addComment = async () => {
  const commentObj = {
    id: nanoid(),
    content: comment.value,
    date: today,
    project: props.project,
  };
  //console.log(commentObj);
  const leadRef = doc(db, "leads", props.data.id);
  await updateDoc(leadRef, {
    comments: arrayUnion(commentObj),
  });
  comment.value = "";
  toggleDetails();
};
const deleteComment = async (c) => {
  const leadRef = doc(db, "leads", props.data.id);
  await updateDoc(leadRef, {
    comments: arrayRemove(c),
  });
};
const toggleDetails = async () => {
  //show_today.value = !show_today.value;
  const leadRef = doc(db, "users", auth.currentUser.uid);
  if (store.display.details != props.data.id) {
    await updateDoc(leadRef, {
      "display.details": props.data.id,
    });
  } else {
    await updateDoc(leadRef, {
      "display.details": "",
    });
  }
};
</script>
<style scoped>
.smalltxt {
  padding: 2px;
}
.reminder {
  padding: 1px;
  font-size: 10px;
}
</style>
