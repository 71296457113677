import { createRouter, createWebHistory } from "vue-router";
import { auth } from "../firebase/config";
import HomeView from "../views/HomeView.vue";
import MainView from "../views/MainView.vue";
import StagesView from "../views/StagesView.vue";
import SettingsView from "../views/SettingsView.vue";
import ImportLeads from "../views/ImportLeads.vue";
import PermissionsView from "../views/PermissionsView.vue";
import UserHome from "../views/UserHome.vue";

const requireAuth = (to, from, next) => {
  let user = auth.currentUser;
  //console.log("checking requireAuth => ", user);
  if (!user) {
    next({ name: "home" });
  } else {
    next();
  }
};
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/main",
    name: "Main",
    component: MainView,
    beforeEnter: requireAuth,
  },
  {
    path: "/stages",
    name: "Stages",
    component: StagesView,
    beforeEnter: requireAuth,
  },
  {
    path: "/settings",
    name: "Settings",
    component: SettingsView,
    beforeEnter: requireAuth,
  },

  {
    path: "/import",
    name: "Import",
    component: ImportLeads,
    beforeEnter: requireAuth,
  },
  {
    path: "/permissions",
    name: "Permissions",
    component: PermissionsView,
    beforeEnter: requireAuth,
  },
  {
    path: "/user",
    name: "User",
    component: UserHome,
    beforeEnter: requireAuth,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
