<template>
  <div class="container">
    <ManagerNav v-if="store.type == 'admin'"></ManagerNav>
    <UserNav v-if="store.type == 'user'"></UserNav>
    <AdminHome v-if="store.type == 'admin'"></AdminHome>
    <UserHomeProjects v-if="store.type == 'user'"></UserHomeProjects>
  </div>
</template>
<script setup>
import { useStore } from "../store/index";
import { onSnapshot, collection, query, where } from "firebase/firestore";
import { onMounted, onBeforeUnmount } from "vue";
import { auth, db } from "../firebase/config";
import ManagerNav from "../components/navbars/ManagerNav.vue";
import UserNav from "../components/navbars/UserNav.vue";
import AdminHome from "../components/home/AdminHome.vue";
import UserHome from "../components/home/UserHome.vue";
import UserHomeProjects from "../components/home/UserHomeProjects.vue";
// import BookingButton from "@/components/home/BookingButton.vue";
const store = useStore();
var unsub = () => {};
onMounted(() => {
  //console.log("MainView user email:", auth.currentUser.email);
  store.setUserSettings({ active: true }); //this is set to prevent the User Deactivated message from showing on freshlogin
  if (auth.currentUser.email) {
    const q = query(
      collection(db, "users"),
      where("email", "==", auth.currentUser.email)
    );
    unsub = onSnapshot(q, (querySnapshot) => {
      //const cities = [];
      querySnapshot.forEach((docy) => {
        if (docy.data().email == auth.currentUser.email) {
          store.setDisplay(docy.data().display);
          store.setUserSettings({
            active: docy.data().active,
            addnewlead: docy.data().addnewlead,
            ...docy.data().settings,
          });
        }
      });
    });
  }

  /////////////////////////////16-05-2024/////////////////////
  // if (auth.currentUser.uid) {
  //   unsub = onSnapshot(doc(db, "users", auth.currentUser.uid), (docy) => {
  //     console.log("MainView -> Current data: ", docy.data().display);
  //     store.setDisplay(docy.data().display);
  //     store.setUserSettings({
  //       active: docy.data().active,
  //       ...docy.data().settings,
  //     });
  //   });
  // }
  ///////////////////////////////16-05-2024//////////////////

  // unsub = onSnapshot(doc(db, "users", auth.currentUser.uid), (docy) => {
  //   console.log("MainView -> Current data: ", docy.data().display);
  //   store.setDisplay(docy.data().display);
  //   store.setUserSettings(docy.data().settings);
  // });
});
onBeforeUnmount(() => {
  console.log("MainView unsubscribing... ");
  unsub();
});
</script>
