<template>
  <div class="container">
    <div class="row border rounded mx-5 mt-3 mb-3">
      <div class="col">
        <input type="text" class="form-control" v-model.trim="project.name" />
      </div>
      <div class="col">
        <textarea
          class="form-control"
          placeholder="Description and deals..."
          rows="3"
          v-model.trim="project.description"
        ></textarea>
      </div>
      <div class="col">
        <button
          class="btn btn-sm"
          :class="getBoostClass()"
          @click="toggleBoost()"
        >
          Boost
        </button>
      </div>
      <div class="col">
        <div class="dropdown">
          <button
            class="btn btn-sm btn-secondary dropdown-toggle"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Users
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li
              class="dropdown-item"
              v-for="u in users"
              :key="u.id"
              @click="toggleUser(u.id, project)"
            >
              {{ u.name }}
              <i
                class="fa fa-check float-end"
                v-if="project.users.includes(u.id)"
                style="color: blue"
              ></i>
            </li>
          </ul>
        </div>
      </div>
      <div class="col">
        <button class="btn btn-sm btn-primary" @click="updateProject()">
          Update
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, onBeforeUnmount } from "vue";
import { useStore } from "../../store/index";
import {
  doc,
  onSnapshot,
  updateDoc,
  collection,
  where,
  query,
  getDocs,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { db } from "../../firebase/config";
const project = ref({});
const users = ref([]);
const store = useStore();
let unsub;
onBeforeUnmount(() => {
  console.log("calling unsub now...");
  unsub();
});
onMounted(async () => {
  unsub = onSnapshot(doc(db, "projects", store.editproject), (docy) => {
    project.value = { id: docy.id, ...docy.data() };
    //console.log("Current data: ", doc.data());
  });
  const q = query(collection(db, "users"), where("admin", "==", store.admin));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((docy) => {
    // doc.data() is never undefined for query doc snapshots
    //console.log(doc.id, " => ", doc.data());
    if (docy.id != store.admin)
      users.value.push({ id: docy.id, ...docy.data() });
  });
});
const toggleBoost = async () => {
  const washingtonRef = doc(db, "projects", store.editproject);
  await updateDoc(washingtonRef, {
    boost: !project.value.boost,
  });
  store.clearEditProject();
};
const getBoostClass = () => {
  let returnClass = null;
  if (project.value.boost) {
    returnClass = "btn-success";
  } else {
    returnClass = "btn-secondary";
  }
  return returnClass;
};
const updateProject = async () => {
  const washingtonRef = doc(db, "projects", store.editproject);
  await updateDoc(washingtonRef, {
    name: project.value.name,
    description: project.value.description,
  });
  store.clearEditProject();
};
const toggleUser = async (user, project) => {
  console.log(user, project.users);
  const washingtonRef = doc(db, "projects", project.id);

  // Atomically add a new region to the "regions" array field.

  if (!project.users.includes(user)) {
    await updateDoc(washingtonRef, {
      users: arrayUnion(user),
    });
  } else {
    await updateDoc(washingtonRef, {
      users: arrayRemove(user),
    });
  }
};
</script>
