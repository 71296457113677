<template>
  <!-- {{ store.settings }} -->
  <div class="container" v-if="store.usersettings.active">
    <div class="row border rounded stdrow">
      <PendingReminders></PendingReminders>
    </div>
    <div class="row border rounded stdrow" v-for="p in allProjects" :key="p.id">
      <div class="col-2">
        <div>
          <span @click="toggleProject(p.id)">{{ p.name }}</span>
        </div>
        <div class="sticky-top">
          <div
            class="border border-success rounded bgyellow"
            v-if="p.id == store.display.project"
          >
            {{ p.description }}
          </div>
          <div
            class="border border-success rounded bggreen table-sm"
            v-if="p.id == store.display.project"
          >
            <ul>
              <li v-for="n in notesArr" :key="n">
                {{ n }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col" v-if="p.id == store.display.project">
        <ProjectLeads :id="p.id" :project="p.name"></ProjectLeads>
      </div>
    </div>
  </div>
  <div class="container" v-if="!store.usersettings.active">
    <div class="row bg-warning">User deactivated!</div>
  </div>
</template>
<script setup>
import { onBeforeUnmount, onMounted, ref, computed } from "vue";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { useStore } from "../../store/index";
import { auth, db } from "../../firebase/config";
import ProjectLeads from "../lead/ProjectLeads";
import PendingReminders from "./PendingReminders.vue";
const store = useStore();
const allProjects = ref([]);

const selectedProject = ref(true);
const notesArr = computed(() => {
  var notes = [];
  notes = store.settings.notes.split(";;");
  return notes;
});
let unsub;

onBeforeUnmount(() => {
  unsub();
});
onMounted(async () => {
  const q = query(
    collection(db, "projects"),
    where("admin", "==", store.admin),
    where("users", "array-contains", store.userid)
  );
  unsub = onSnapshot(q, (querySnapshot) => {
    const cities = [];
    querySnapshot.forEach((docy) => {
      cities.push({ id: docy.id, ...docy.data() });
    });
    allProjects.value = cities.sort((a, b) => {
      return b.boost - a.boost; //this is done to sort projects as per boost
    });
  });
});
const toggleProject = async (id) => {
  console.log("inside togggle project");
  const leadRef = doc(db, "users", auth.currentUser.uid);
  if (id != store.display.project) {
    // await updateDoc(leadRef, {
    //   "display.project": arrayUnion(id),
    // });
    await updateDoc(leadRef, {
      "display.project": id,
    });
  } else {
    // await updateDoc(leadRef, {
    //   "display.project": arrayRemove(id),
    // });
    await updateDoc(leadRef, {
      "display.project": "",
    });
  }
};
</script>
<style scoped>
.bggreen {
  background-color: #defde0;
}
.bgblue {
  background-color: #def3fd;
}
.bgred {
  background-color: #fddfdf;
}
.bgyellow {
  background-color: #fcf7de;
}
.table-sm {
  font-size: 12px;
}
</style>
