<template>
  <div class="card-body">
    <div class="card-text" @click="toggleLead(leaddata.projects)">
      {{ leaddata.name }}
    </div>
  </div>
</template>
<script setup>
import { defineProps, onBeforeMount, onBeforeUnmount, ref } from "vue";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db, auth } from "../../firebase/config";
import { useStore } from "../../store/index";
const props = defineProps(["leadid", "reminderid"]);
const leaddata = ref({});
const store = useStore();
var unsub = () => {};
onBeforeUnmount(() => {
  unsub();
});
onBeforeMount(() => {
  unsub = onSnapshot(doc(db, "leads", props.leadid), (docy) => {
    leaddata.value = { ...docy.data() };
  });
});
const toggleLead = async (id) => {
  const leadRef = doc(db, "users", auth.currentUser.uid);
  if (props.leadid != store.display.details) {
    await updateDoc(leadRef, {
      "display.project": id,
      "display.details": props.leadid,
    });
  } else {
    await updateDoc(leadRef, {
      "display.project": "",
      "display.details": "",
    });
  }
};
</script>
<style scoped>
.phonebutton {
  font-size: 10px;
  padding: 2px;
}
.row {
  font-size: 10px;
}
</style>
