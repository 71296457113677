<template>
  <ul class="list-group">
    <li class="list-group-item" v-for="u in users" :key="u.id">
      <div class="row">
        <div class="col">{{ u.name }}</div>
        <div class="col">
          <span class="text-muted">{{ u.email }}</span>
        </div>
        <div class="col">
          <button
            class="btn btn-sm btn-primary"
            @click="toggleAddNewLead(u.id, u.addnewlead)"
            v-if="u.addnewlead"
          >
            AddNew
          </button>
          <button
            class="btn btn-sm btn-danger"
            @click="toggleAddNewLead(u.id, u.addnewlead)"
            v-if="!u.addnewlead"
          >
            NotAdd
          </button>
        </div>
        <div class="col">
          <button
            class="btn btn-sm btn-primary float-end"
            v-if="u.active"
            @click="toggleActive(u.id, u.active)"
          >
            Active
          </button>
          <button
            class="btn btn-sm btn-danger float-end"
            @click="toggleActive(u.id, u.active)"
            v-if="!u.active"
          >
            Deactive
          </button>
        </div>
      </div>
    </li>
  </ul>
</template>
<script setup>
import { onBeforeUnmount, onMounted, ref } from "vue";
import {
  collection,
  onSnapshot,
  query,
  where,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import { useStore } from "../../store/index";
const store = useStore();
const users = ref([]);
var unsubscribe = () => {};
onBeforeUnmount(() => {
  unsubscribe();
});
onMounted(() => {
  const q = query(collection(db, "users"), where("admin", "==", store.admin));
  unsubscribe = onSnapshot(q, (querySnapshot) => {
    const cities = [];
    querySnapshot.forEach((docy) => {
      if (docy.id != store.admin) {
        cities.push({ id: docy.id, ...docy.data() });
      }
    });
    users.value = cities;
    //console.log("Current cities in CA: ", cities.join(", "));
  });
});

const toggleActive = async (id, val) => {
  const washingtonRef = doc(db, "users", id);

  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    active: !val,
  });
};
const toggleAddNewLead = async (id, val) => {
  //console.log(id, val);
  const washingtonRef = doc(db, "users", id);

  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    addnewlead: !val,
  });
};
</script>
