<template>
  <div class="container">
    <ManagerNav v-if="store.type == 'admin'"></ManagerNav>
    <UserNav v-if="store.type == 'user'"></UserNav>
    <PermissionsMenu if="store.type == 'admin'"></PermissionsMenu>

    <!-- <BookingButton v-if="store.type == 'user'"></BookingButton> -->
  </div>
</template>
<script setup>
import { useStore } from "../store/index";
import ManagerNav from "../components/navbars/ManagerNav.vue";
import UserNav from "../components/navbars/UserNav.vue";

import PermissionsMenu from "../components/permissions/PermissionsMenu.vue";
// import BookingButton from "@/components/home/BookingButton.vue";
const store = useStore();
</script>
