<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">User </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a
              class="nav-link active"
              aria-current="page"
              href="#"
              @click="toMain()"
              >Home</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Link</a>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Settings
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li class="dropdown-item" @click="toSettings()">Config</li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
              <li><hr class="dropdown-divider" /></li>
              <li><a class="dropdown-item" href="#">Something else here</a></li>
            </ul>
          </li>
        </ul>

        <button class="btn btn-sm btn-outline-secondary" @click="logout()">
          Logout
        </button>
      </div>
    </div>
  </nav>
</template>
<script setup>
import { signOut } from "firebase/auth";
import { auth, db } from "../../firebase/config";
import { useRouter } from "vue-router";
import { useStore } from "../../store/index";
import { onBeforeUnmount, onMounted } from "vue";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
} from "firebase/firestore";
const store = useStore();
const router = useRouter();
let unsub;
const logout = () => {
  signOut(auth)
    .then(() => {
      // Sign-out successful.
      store.clearUser();
      router.push("/");
    })
    .catch((error) => {
      // An error happened.
    });
  //router.push("/");
};
onBeforeUnmount(() => {
  unsub();
});
onMounted(async () => {
  const k = query(
    collection(db, "settings"),
    where("admin", "==", store.admin)
  );
  unsub = onSnapshot(k, (querySnapshot) => {
    const cities = [];
    querySnapshot.forEach((docy) => {
      cities.push({ id: docy.id, ...docy.data() });
    });
    store.setSettings(cities[0]);
  });
  /////////stages//////////////////////////
  const q = query(collection(db, "stages"), where("admin", "==", store.admin));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((docy) => {
    // doc.data() is never undefined for query doc snapshots
    //console.log(docy.id, " => ", docy.data());
    store.addStage({ id: docy.id, ...docy.data() });
  });
  /////////////////////////////////////
});
const toSettings = () => {
  router.push("/settings");
};
const toMain = () => {
  router.push("/main");
};
</script>
