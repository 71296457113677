<template>
  <!-- STORE:
  <pre>{{ store.display }}</pre> -->
  <div class="row" v-show="false">
    <ul class="list-group list-group-horizontal">
      <li
        class="list-group-item"
        v-for="s in sortedStages"
        :key="s.id"
        :class="getStageColour(s.id)"
      >
        {{ s.name }}: {{ getCount(s.id) }}
        <span class="text-primary"
          >({{
            parseFloat((getCount(s.id) / allLeads.length) * 100).toFixed(2)
          }}
          %)</span
        >
      </li>
    </ul>
  </div>
  <div class="row my-1 sticky-top opacity-100 rounded">
    <div class="col smalltxt d-flex justify-content-around">
      <button
        class="btn btn-sm btn-outline-success"
        v-if="store.usersettings.addnewlead"
        @click="toggleNewLead()"
      >
        New
      </button>
      <span>Alarm</span>
    </div>
    <div class="col smalltxt">
      <button
        class="btn btn-sm btn-outline-primary dropdown-toggle"
        type="button"
        id="dropdownMenu2"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Stages
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
        <li
          v-for="st in sortedStages"
          :key="st.id"
          class="dropdown-item midtxt border-top"
          :class="getStageColour(st.id)"
          @click="displayStage(st.id)"
        >
          {{ st.name }}
          <i
            class="fa fa-check float-start align-middle"
            v-if="checkIfStageSelected(st.id)"
          ></i>
          <span
            class="badge rounded-pill bg-primary float-end"
            v-show="getStageCount(st.id)"
            >{{ getStageCount(st.id) }}</span
          >
        </li>
        <li class="dropdown-item midtxt border-top">
          Total
          <span
            class="badge rounded-pill bg-success float-end"
            v-show="getTotalCount()"
            >{{ getTotalCount() }}</span
          >
        </li>
      </ul>
      <i
        class="fa fa-close mx-auto float-end"
        v-if="store.display.stages.length"
        @click="clearDisplayStage()"
      ></i>
    </div>
    <div class="col smalltxt">
      <button
        class="btn btn-sm btn-outline-primary dropdown-toggle"
        type="button"
        id="dropdownMenu2"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Tags
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
        <li
          v-for="t in store.settings.tags"
          :key="t"
          class="dropdown-item midtxt border-top"
          @click="toggleTag(t)"
          :class="{ 'bg-primary': ifTagPresent(t) }"
        >
          {{ t }}
          <span
            class="badge rounded-pill bg-secondary float-end smalltxt"
            v-show="getTagCount(t)"
            >{{ getTagCount(t) }}</span
          >
        </li>
      </ul>
      <i
        class="fa fa-close float-end"
        v-if="store.display.tags.length"
        @click="clearTags()"
      ></i>
    </div>
    <div class="col smalltxt">Name</div>
    <div class="col smalltxt">Phone</div>

    <div class="col smalltxt">
      <button
        class="btn btn-sm btn-outline-primary dropdown-toggle"
        type="button"
        id="dropdownMenu3"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Updated
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenu3">
        <li class="dropdown-item" @click="toggleToday()">
          Hide Today
          <i class="fa fa-check float-end" v-show="!store.display.today"></i>
        </li>
        <li class="dropdown-item" @click="sortUpdated('new')">Newest first</li>
        <li class="dropdown-item" @click="sortUpdated('old')">Oldest first</li>
      </ul>
      <i
        class="fa fa-exclamation-circle mx-1"
        v-show="!store.display.today"
        style="color: red"
      ></i>
    </div>
    <div class="col smalltxt">Reminder</div>
    <div class="col smalltxt">
      Comments

      <i
        class="fa fa-file-excel-o float-end"
        @click="arrayToExcel(export_data, nameOfFile())"
      ></i>
    </div>
    <!-- <div class="col smalltxt">Details</div> -->
    <!-- {{ sorted_leads }} -->
  </div>
  <div class="row" v-if="showNewLead">
    <NewLead
      :leads="sorted_leads"
      :project="props.id"
      @lead-added="closeNewLead()"
    ></NewLead>
  </div>
  <div class="row" v-for="l in sorted_leads" :key="l.id">
    <LeadComponent :data="l" :project="props.id"></LeadComponent>
  </div>
</template>

<script setup>
const XLSX = require("xlsx");
const dayjs = require("dayjs");
import {
  computed,
  defineProps,
  onBeforeUnmount,
  onMounted,
  onUpdated,
  ref,
} from "vue";
import { useStore } from "../../store/index";
import { auth, db } from "../../firebase/config";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import LeadComponent from "./LeadComponent.vue";
import NewLead from "./NewLead.vue";
const allLeads = ref([]);
const display_stage = ref("");
const sort_updated = ref("");
const sort_added = ref("");
const convertToArrayOfArrays = () => {
  var data = [];
  data.push([nameOfFile()]);
  data.push(["Name", "Email", "Phone"]);
  sorted_leads.value.forEach((d) => {
    var tempArr = [];
    tempArr.push(d.name);
    tempArr.push(d.email);
    tempArr.push(d.phone);

    data.push(tempArr);
  });
  return data;
};

const showNewLead = ref(false);
function arrayToExcel(data, fileName = "output.xlsx") {
  // Create a new workbook
  data = convertToArrayOfArrays(data);
  const workbook = XLSX.utils.book_new();

  // Convert the array to a worksheet
  const worksheet = XLSX.utils.aoa_to_sheet(data);

  // Append the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Write the workbook to a file
  XLSX.writeFile(workbook, fileName);

  console.log(`Excel file created: ${fileName}`);
}
var nameOfFile = () => {
  var name = "";
  name = props.project + "_" + dayjs().format("DD_MM_YYYY_HH_mm") + ".xlsx";
  return name;
};
// const data = [
//   ["Name", "Age", "Email"],
//   ["Alice", 30, "alice@example.com"],
//   ["Bob", 25, "bob@example.com"],
//   ["Charlie", 35, "charlie@example.com"],
// ];
const closeNewLead = () => {
  showNewLead.value = false;
};
const toggleNewLead = () => {
  showNewLead.value = !showNewLead.value;
};
const checkIfStageSelected = (id) => {
  var val = false;
  if (store.display.stages.includes(id)) {
    val = true;
  }
  return val;
};
const getTotalCount = () => {
  var count = 0;
  allLeads.value.forEach(() => {
    count++;
  });
  return count;
};
const ifTagPresent = (t) => {
  if (store.display.tags.includes(t)) return true;
  return false;
};
// const selectTag = (t) => {
//   if (!tags.value.includes(t)) {
//     tags.value.push(t);
//   }
// };
const toggleToday = async () => {
  //show_today.value = !show_today.value;
  const leadRef = doc(db, "users", auth.currentUser.uid);
  await updateDoc(leadRef, {
    //showUpdatedToday: !show_today.value,
    "display.today": !store.display.today,
  });
  //store.setDisplayToday(!show_today.value);
};
const sortUpdated = async (val) => {
  sort_added.value = "";
  if (sort_updated.value != val) {
    sort_updated.value = val;
  } else {
    sort_updated.value = "";
  }
  const leadRef = doc(db, "users", auth.currentUser.uid);
  await updateDoc(leadRef, {
    "display.sortby": val,
  });
};

const sorted_leads = computed(() => {
  var leads = [];
  var checkIfAllUpdateValuesAreValid = true;
  allLeads.value.forEach((i) => {
    //even if a single update field value is false DO NOT sort the array
    if (!i.updated) {
      checkIfAllUpdateValuesAreValid = false;
    }
  });
  if (store.display.stages.length) {
    allLeads.value.forEach((l) => {
      if (store.display.stages.includes(l.stages)) {
        leads.push({ ...l });
      }
    });
  } else {
    // sort as per the priority
    allLeads.value.forEach((l) => {
      leads.push({ ...l });
    });
  }
  if (checkIfAllUpdateValuesAreValid) {
    leads.sort((a, b) => {
      if (store.display.sortby == "new") {
        return b.updated.seconds - a.updated.seconds;
      }
      if (store.display.sortby == "old") {
        return a.updated.seconds - b.updated.seconds;
      }
    });
  }
  return leads;
});
const store = useStore();
const allStages = ref(store.stages);
const resultData = {};

const props = defineProps({
  id: String,
  project: String,
});
const displayStage = async (id) => {
  if (display_stage.value != id) {
    display_stage.value = id;
  } else {
    display_stage.value = "";
  }
  const leadRef = doc(db, "users", auth.currentUser.uid);
  if (!store.display.stages.includes(id)) {
    await updateDoc(leadRef, {
      "display.stages": arrayUnion(id),
    });
  } else {
    await updateDoc(leadRef, {
      "display.stages": arrayRemove(id),
    });
  }
};
const clearDisplayStage = async () => {
  display_stage.value = "";
  const leadRef = doc(db, "users", auth.currentUser.uid);
  await updateDoc(leadRef, {
    "display.stages": [],
  });
};
//const emit = defineEmits(["resultsReady"]);
let unsubscribe = () => {};
let unsub = () => {};
onMounted(() => {
  const q = query(
    collection(db, "leads"),
    //where("projects", "array-contains", props.id),
    where("projects", "==", props.id),
    //where("users", "==", auth.currentUser.uid)
    where("users", "array-contains", auth.currentUser.uid)
  );
  unsubscribe = onSnapshot(q, (querySnapshot) => {
    const cities = [];
    querySnapshot.forEach((docy) => {
      cities.push({ id: docy.id, ...docy.data() });
    });
    allLeads.value = cities;
  });
});
onBeforeUnmount(() => {
  console.log("Unmounting: ", props.id);
  unsubscribe();
  unsub();
});

const sortedStages = computed(() => {
  const arr = [];
  let next_id;
  const first = allStages.value.filter((st) => st.next == "end");
  if (first[0]) {
    //find last stage
    arr.unshift(first[0]);
    next_id = first[0].id;
  }
  for (let i = 0; i < allStages.value.length; i++) {
    allStages.value.forEach((as) => {
      if (as.next == next_id && !arr.includes(as)) {
        arr.unshift(as);
        next_id = as.id;
      }
    });
  }
  return arr;
});
const getCount = (stageid) => {
  let count = 0;
  allLeads.value.forEach((lead) => {
    if (lead.stages["projectID_" + props.id] == stageid) {
      count++;
    }
  });
  resultData[stageid] = count;
  return count;
};
const getStageColour = (stage) => {
  // const stage = props.data.stages["projectID_" + props.project];
  let returnClass = "";
  if (store.settings.colours.defde0 == stage) {
    returnClass = "bggreen";
  }
  if (store.settings.colours.def3fd == stage) {
    returnClass = "bgblue";
  }
  if (store.settings.colours.fddfdf == stage) {
    returnClass = "bgred";
  }
  if (store.settings.colours.fcf7de == stage) {
    returnClass = "bgyellow";
  }
  return returnClass;
};

const toggleTag = async (t) => {
  const leadRef = doc(db, "users", auth.currentUser.uid);
  if (!store.display.tags.includes(t)) {
    await updateDoc(leadRef, {
      "display.tags": arrayUnion(t),
    });
  } else {
    await updateDoc(leadRef, {
      "display.tags": arrayRemove(t),
    });
  }
};
const clearTags = async (t) => {
  const leadRef = doc(db, "users", auth.currentUser.uid);
  await updateDoc(leadRef, {
    "display.tags": [],
  });
};
const getTagCount = (t) => {
  var count = 0;
  allLeads.value.forEach((l) => {
    if (l.tags.includes(t)) {
      count++;
    }
  });
  return count;
};
const getStageCount = (stage) => {
  var count = 0;
  // console.log(stage, props.id);
  allLeads.value.forEach((l) => {
    if (l.stages == stage) {
      count++;
    }
    // for (const property in l.stages) {
    //   //console.log(`${property}: ${object[property]}`);
    //   // if (property == "projectID_" + props.id && l.stages[property] == stage) {
    //   //   count++;
    //   // }
    //   if (property == "projectID_" + props.id && l.stages[property] == stage) {
    //     count++;
    //   }
    // }
  });
  return count;
};
</script>
<style scoped>
.list-group-item {
  padding: 3px;
  font-size: 10px;
}
.bggreen {
  background-color: #defde0;
}
.bgblue {
  background-color: #def3fd;
}
.bgred {
  background-color: #fddfdf;
}
.bgyellow {
  background-color: #fcf7de;
}
.smalltxt {
  font-size: 10px;
}
.midtxt {
  font-size: 12px;
}
.btn-sm {
  padding: 1px;
  font-size: 10px;
}
.sticky-top {
  background-color: rgba(
    255,
    255,
    255,
    0.99
  ); /* White color with 80% opacity */
}
</style>
