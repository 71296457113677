<template>
  <!-- {{ store.display.today }} -->
  <div
    class="row border rounded align-items-center"
    :class="getStageColour()"
    v-show="displayTags()"
  >
    <div class="col">
      <i
        class="fa fa-bell float-start"
        style="color: red"
        v-if="showReminderIcon"
        @click="clearReminderToggle()"
      ></i>
      <i
        class="fa fa-check float-end"
        style="color: green"
        v-if="reminderClear"
        @click="reminderDone()"
      ></i>
    </div>
    <div class="col">
      <div class="dropdown">
        <button
          class="btn btn-sm btn-outline-primary dropdown-toggle"
          type="button"
          id="dropdownMenu2"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{ getStageName() }}
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
          <li v-for="st in sortedStages" :key="st.id">
            <button
              class="dropdown-item"
              type="button"
              :class="getActive(st.id)"
              @click="updateStage(st.id)"
            >
              {{ st.name }}
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="col">
      <span v-for="tg in props.data.tags" :key="tg">
        <span
          class="badge rounded-pill bg-secondary tinytxt"
          @click="selectTag(tg)"
        >
          {{ tg }}
        </span>
        <i
          class="fa fa-close"
          style="font-size: 8px"
          v-if="tagToDelete == tg"
          @click="deleteTag(tg)"
        ></i>
      </span>

      <div class="dropdown float-end">
        <button
          class="btn btn-sm dropdown-toggle"
          type="button"
          id="dropdownMenu2"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        ></button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
          <li v-for="t in store.settings.tags" :key="t">
            <button class="dropdown-item" type="button" @click="addTag(t)">
              {{ t }}
            </button>
          </li>
        </ul>
      </div>
      <!-- {{ props.data.tags }} -->
    </div>
    <div class="col smalltxt align-middle" @click="toggleDetails()">
      {{ props.data.name }}
    </div>
    <div class="col align-items-center">
      <span v-for="p in props.data.phone" :key="p">
        <a
          class="btn btn-sm btn-primary phonebutton"
          :href="`tel:${p}`"
          role="button"
          @click="logCall()"
          v-if="store.settings.showphone"
        >
          {{ maskedPhone(p) }}
        </a>
        <a
          class="btn btn-sm btn-secondary phonebutton"
          role="button"
          v-if="!store.settings.showphone"
        >
          {{ maskedPhone(p) }}
        </a>
      </span>
    </div>

    <div class="col smalltxt align-items-center">
      <div v-if="props.data.updated">{{ updatedOn(props.data.updated) }}</div>
    </div>
    <div class="col align-items-center" @click="toggleDetails()">
      <span class="smalltxt align-middle">{{ reminderDate }}</span>
      <!-- <div class="row rmdrcol">
        <table>
          <tr>
            <td>
              <i
                class="bi bi-alarm editbtn"
                style="font-size: 12px"
                @click="showReminderOptions()"
              ></i>
            </td>
            <td class="smalltxt">
              <span
                class="smalltxt align-middle"
                @click="selectToDeleteReminder(reminderDate)"
                >{{ reminderDate }}</span
              >
            </td>
            <td>
              <i
                class="fa fa-trash-o float-end"
                style="font-size: 12px"
                v-if="deleteReminder"
                @click="reminderDelete()"
              ></i>
            </td>
          </tr>
        </table>
      </div> -->

      <!-- <div class="row border" v-if="showReminder">
        <div class="col">
          <span
            class="btn btn-sm btn-primary mx-1 mx-auto"
            @click="setReminder('tomorrow')"
            >1d</span
          >
          <span class="btn btn-sm btn-primary mx-1" @click="setReminder('week')"
            >1w</span
          >
          <span
            class="btn btn-sm btn-primary mx-1"
            @click="setReminder('month')"
            >1m</span
          >
          <input
            type="date"
            class="form-control reminder mx-1 my-1"
            v-model.trim="date"
            @change="setReminder()"
          />
        </div>
      </div> -->
    </div>
    <div class="col smalltxt" @click="toggleDetails()">
      <!-- {{ props.data.comments }} {{ latestComment(props.data.comments) }} -->
      {{ latestComment() }}
    </div>
    <div class="row mx-auto" v-if="store.display.details == props.data.id">
      <div class="col">
        <LeadDetails :data="props.data" :project="props.project"></LeadDetails>
      </div>
    </div>
  </div>
  <!-- <div
    class="row border rounded align-items-center mb-3"
    :class="getStageColour()"
    v-if="toggleDetails"
  >
    <div class="row smalltxt">
      <div class="col"></div>
      <div class="col"></div>
      <div class="col"></div>
      <div class="col">{{ store.usersettings.notes }}</div>
      <div class="col">
        <div class="input-group mb-3 smalltxt">
          <input
            type="text"
            class="form-control"
            placeholder="Comment"
            aria-describedby="button-addon2"
          />
          <button
            class="btn btn-outline-secondary"
            type="button"
            id="button-addon2"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  </div> -->
  <!-- <LeadDetails
    v-if="store.display.details == props.data.id"
    :data="props.data"
    :project="props.project"
  ></LeadDetails> -->
</template>
<script setup>
import { defineProps, computed, ref } from "vue";
import { useStore } from "../../store/index";
import {
  doc,
  updateDoc,
  serverTimestamp,
  arrayUnion,
  arrayRemove,
  deleteDoc,
  query,
  where,
  collection,
  getDocs,
} from "firebase/firestore";
import { auth, db } from "../../firebase/config";
import LeadDetails from "./LeadDetails.vue";
const dayjs = require("dayjs");
const date = ref("");
const props = defineProps(["data", "project"]);
const store = useStore();
const allStages = ref(store.stages);
const showReminder = ref(false);
const reminderClear = ref(false);
const deleteReminder = ref(false);
//const toggleDetails = ref(false);
const tagToDelete = ref("");
const logCall = async () => {
  console.log(props.data.id);
  const leadRef = doc(db, "leads", props.data.id);
  await updateDoc(leadRef, {
    activitylog: arrayUnion({
      type: "call",
      date: dayjs().format("YYYY-MM-DD HH:mm"),
      data: "Phone",
    }),
  });
};
const stageLog = async (id) => {
  console.log(id);
  const leadRef = doc(db, "leads", props.data.id);
  await updateDoc(leadRef, {
    activitylog: arrayUnion({
      type: "stage",
      date: dayjs().format("YYYY-MM-DD HH:mm"),
      data: id,
    }),
  });
};
function checkMatchingValues(arr1, arr2) {
  return arr1.some((value) => arr2.includes(value));
}
const toggleDetails = async () => {
  //show_today.value = !show_today.value;
  const leadRef = doc(db, "users", auth.currentUser.uid);
  if (store.display.details != props.data.id) {
    await updateDoc(leadRef, {
      "display.details": props.data.id,
    });
  } else {
    await updateDoc(leadRef, {
      "display.details": "",
    });
  }
};
const displayTags = () => {
  if (!store.display.today && props.data.updated) {
    const timestamp = props.data.updated.seconds * 1000;
    const formattedDate = dayjs(timestamp).format("DD-MM-YYYY");
    if (formattedDate == dayjs().format("DD-MM-YYYY")) return false;
  }
  if (!store.display.tags.length) {
    //if no tags selected display all
    return true;
  } else {
    if (checkMatchingValues(store.display.tags, props.data.tags)) {
      return true;
    } else {
      return false;
    }
  }
};
const selectTag = (t) => {
  if (tagToDelete.value != t) {
    tagToDelete.value = t;
  } else {
    tagToDelete.value = "";
  }
};
const selectToDeleteReminder = () => {
  deleteReminder.value = !deleteReminder.value;
};
const clearReminderToggle = () => {
  reminderClear.value = !reminderClear.value;
};
const sortedStages = computed(() => {
  const arr = [];
  let next_id;
  const first = allStages.value.filter((st) => st.next == "end");
  if (first[0]) {
    //find last stage
    arr.unshift(first[0]);
    next_id = first[0].id;
  }
  for (let i = 0; i < allStages.value.length; i++) {
    allStages.value.forEach((as) => {
      if (as.next == next_id && !arr.includes(as)) {
        arr.unshift(as);
        next_id = as.id;
      }
    });
  }
  return arr;
});
const showReminderOptions = () => {
  showReminder.value = !showReminder.value;
};
const getStageName = () => {
  let stage = "";
  let name = "";
  //stage = props.data.stages["projectID_" + props.project];
  stage = props.data.stages;
  sortedStages.value.forEach((st) => {
    if (st.id == stage) {
      name = st.name;
    }
  });
  return name;
};

const getActive = (id) => {
  //if (props.data.stages["projectID_" + props.project] == id) return "active";
  if (props.data.stages == id) return "active";
};
const updateStage = async (id) => {
  const frankDocRef = doc(db, "leads", props.data.id);
  await updateDoc(frankDocRef, {
    stages: id,
    updated: serverTimestamp(),
  });
  stageLog(id);
  const reportObj = {
    from: props.data.stages,
    to: id,
    lead: props.data.id,
  };
  reportEntry(reportObj);
};
const reportEntry = (obj) => {
  console.log(obj);
};
const createdOn = (timestamp) => {
  const date = new Date(timestamp.seconds * 1000);
  const formattedDate = dayjs(date).format("DD-MM-YYYY");
  return formattedDate;
};
const updatedOn = (timestamp) => {
  let formattedDate;
  const date = new Date(timestamp.seconds * 1000);
  formattedDate = dayjs(date).format("DD-MM-YYYY HH:mm");
  return formattedDate;
};
const getStageColour = () => {
  //const stage = props.data.stages["projectID_" + props.project];
  const stage = props.data.stages;
  let returnClass = "";
  if (store.settings.colours.defde0 == stage) {
    returnClass = "bggreen";
  }
  if (store.settings.colours.def3fd == stage) {
    returnClass = "bgblue";
  }
  if (store.settings.colours.fddfdf == stage) {
    returnClass = "bgred";
  }
  if (store.settings.colours.fcf7de == stage) {
    returnClass = "bgyellow";
  }
  if (store.display.details == props.data.id) {
    returnClass = returnClass + " border-primary border-2";
  }
  return returnClass;
};
const setReminder = async (str) => {
  const today = new Date();
  const leadRef = doc(db, "leads", props.data.id);
  if (str == "tomorrow") {
    date.value = dayjs(today).add(1, "day").format("YYYY-MM-DD");
  }
  if (str == "week") {
    date.value = dayjs(today).add(1, "week").format("YYYY-MM-DD");
  }
  if (str == "month") {
    date.value = dayjs(today).add(1, "month").format("YYYY-MM-DD");
  }
  //console.log("set reminder for =>", date.value);
  //console.log(props.data.id, props.project);
  const reminderObj = {
    project: props.project,
    date: date.value,
  };
  props.data.reminders.forEach(async (r) => {
    if (r.project == props.project) {
      //only one reminder per project allowed
      await updateDoc(leadRef, {
        reminders: arrayRemove(r),
      });
    }
  });
  await updateDoc(leadRef, {
    reminders: arrayUnion(reminderObj),
  });
  date.value = "";
  showReminder.value = false;
};
const reminderDate = computed(() => {
  var re = "";
  props.data.reminders.forEach((r) => {
    if (r.project == props.project) {
      re = r.date;
    }
  });
  return re;
});
const showReminderIcon = computed(() => {
  var show = false;
  const today = new Date();
  ///console.log("reminderdate=>", reminderDate.value);
  //dayjs(today).diff(reminderDate.value, "day");
  if (
    reminderDate.value &&
    dayjs(today).format("YYYY-MM-DD") >= reminderDate.value
  ) {
    show = true;
  }
  return show;
});
const reminderDone = async () => {
  const leadRef = doc(db, "leads", props.data.id);
  props.data.reminders.forEach(async (r) => {
    if (r.project == props.project) {
      //only one reminder per project allowed
      await updateDoc(leadRef, {
        reminders: arrayRemove(r),
      });
    }
  });
  const q = query(
    collection(db, "reminders"),
    where("leadid", "==", props.data.id),
    where("userid", "==", store.userid)
  );
  //await deleteDoc(q);
  const querySnapshot = await getDocs(q);
  const reminder_to_delete = [];
  querySnapshot.forEach(async (docy) => {
    // doc.data() is never undefined for query doc snapshots
    //console.log(docy.id, " => ", docy.data());
    //await deleteDoc(docy.id);
    reminder_to_delete.push(docy.id);
  });
  if (reminder_to_delete.length) {
    reminder_to_delete.forEach(async (rd) => {
      await deleteDoc(doc(db, "reminders", rd));
    });
  }
  reminderClear.value = false;
  deleteReminder.value = false;
};
const reminderDelete = async () => {
  const leadRef = doc(db, "leads", props.data.id);
  props.data.reminders.forEach(async (r) => {
    if (r.project == props.project) {
      //only one reminder per project allowed
      await updateDoc(leadRef, {
        reminders: arrayRemove(r),
      });
    }
  });
  deleteReminder.value = false;
  reminderClear.value = false;
};
const addTag = async (t) => {
  const leadRef = doc(db, "leads", props.data.id);
  await updateDoc(leadRef, {
    tags: arrayUnion(t),
  });
};
const deleteTag = async (t) => {
  const leadRef = doc(db, "leads", props.data.id);
  await updateDoc(leadRef, {
    tags: arrayRemove(t),
  });
  tagToDelete.value = "";
};
const latestComment = () => {
  var comm = "";
  if (props.data.comments.length) {
    comm = props.data.comments[props.data.comments.length - 1].content;
  }
  return comm;
};
const maskedPhone = (p) => {
  var ph = "";
  if (store.settings.showphone) {
    ph = p;
  } else {
    ph = maskString(p);
  }
  return ph;
};
function maskString(str) {
  return str.slice(0, -4).replace(/./g, "X") + str.slice(-4);
}
</script>
<style scoped>
.col {
  padding: 1px;
}
.row:hover {
  background-color: #d7e9f7;
  /* font-size: 14px; */
  /* border: 2px;
  border-color: blue; */
}
.phonebutton {
  font-size: 10px;
  padding: 1px;
}
.btn-outline-primary {
  font-size: 10px;
  padding: 2px;
}
.smalltxt {
  font-size: 10px;
}
.tinytxt {
  font-size: 8px;
}
.bggreen {
  background-color: #defde0;
}
.bgblue {
  background-color: #def3fd;
}
.bgred {
  background-color: #fddfdf;
}
.bgyellow {
  background-color: #fcf7de;
}
.reminder {
  padding: 1px;
  font-size: 10px;
}
.btn-sm {
  padding: 1px;
  font-size: 10px;
}
.rmdrcol:hover .editbtn {
  opacity: 1;
  transition: 0.5s;
}
.commentcol:hover .commentbtn {
  opacity: 1;
  transition: 0.5s;
}
.commentbtn {
  opacity: 0;
}
.editbtn {
  opacity: 0;
}
</style>
