<template>
  <div class="row">
    <div class="col">
      <div class="dropdown">
        <button
          class="btn btn-secondary dropdown-toggle mt-1"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span v-if="selectedProject">{{ getProjectName() }}</span>
          <span v-else> Select group... </span>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li
            class="dropdown-item"
            v-for="p in projects"
            :key="p.id"
            @click="selectProject(p.id)"
          >
            {{ p.name }}
          </li>
        </ul>
      </div>
    </div>
    <div class="col">
      <div class="dropdown">
        <button
          class="btn btn-secondary dropdown-toggle mt-1"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span v-if="selectedStage">{{ getStageName() }}</span>
          <span v-else> Select stage... </span>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li
            class="dropdown-item"
            v-for="s in sortedStages"
            :key="s.id"
            @click="selectStage(s.id)"
          >
            {{ s.name }}
          </li>
        </ul>
      </div>
    </div>
    <div class="col">
      <div class="dropdown">
        <button
          class="btn btn-secondary dropdown-toggle mt-1"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span v-if="selectedUser">{{ getUserName() }}</span>
          <span v-else> Select user... </span>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li
            class="dropdown-item"
            v-for="u in sortedUsers"
            :key="u.id"
            @click="selectUser(u.id)"
          >
            <!-- {{ u.name }} -->
            {{ fetchUserName(u.id) }}
          </li>
          <li class="dropdown-item" @click="selectUser('random')">Random</li>
        </ul>
      </div>
    </div>
    <div class="col">
      <button
        v-if="
          selectedProject && selectedUser && selectedStage && excelData.length
        "
        class="btn btn-primary mt-1"
        @click="importData()"
      >
        Import
      </button>
      <!-- <button
        v-if="excelData.length"
        class="btn btn-primary mt-1"
        @click="importData()"
      >
        Import
      </button> -->
    </div>
  </div>
  <i
    class="fa fa-close float-end"
    style="color: red"
    v-if="excelData.length > 0"
    @click="forgetFile()"
  ></i>
  <table v-if="excelData.length > 0" class="table table-sm mt-1">
    <thead>
      <tr>
        <th></th>
        <th v-for="(cell, index) in excelData[0]" :key="index">
          {{ cell }}
        </th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(row, rowIndex) in excelData.slice(1)"
        :key="rowIndex"
        class="border rounded smallList"
      >
        <td>
          <i class="fa fa-close" @click="removeFromUpload(row)"></i>
        </td>
        <td v-for="(cell, cellIndex) in row" :key="cellIndex">
          <!-- {{ cell }} -->
          <div v-if="cellIndex != 1">
            {{ cell }}
          </div>
          <div
            v-if="cellIndex == 1"
            :class="[checkIfLeadExists(String(cell)) ? 'bg-warning' : '']"
          >
            {{ cell }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <input type="file" ref="file" class="mt-1" @change="handleFileUpload" />
  <div class="row">
    <div class="col" v-if="success.length">
      <ul class="list-group">
        <li class="list-group-item" v-for="s in success" :key="s">{{ s }}</li>
      </ul>
    </div>
    <div class="col" v-if="failure.length">
      <ul class="list-group">
        <li class="list-group-item" v-for="f in failure" :key="f">{{ f }}</li>
      </ul>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed, watchEffect } from "vue";
import { db } from "../../firebase/config";
import { useStore } from "../../store/index";
import {
  collection,
  query,
  where,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";

const store = useStore();
let XLSX = require("xlsx");
const excelData = ref([]);
const projects = ref([]);
const users = ref([]);
const stages = ref(store.stages);
const selectedProject = ref("");
const selectedUser = ref("");
const selectedStage = ref("");
const projectLeads = ref([]);
const success = ref([]);
const failure = ref([]);
const fetchUserName = (id) => {
  var name = "";
  users.value.forEach((u) => {
    if (u.id == id) {
      name = u.name;
    }
  });
  return name;
};
const sortedUsers = computed(() => {
  var arr = [];
  projects.value.forEach((p) => {
    if (p.id == selectedProject.value) {
      //arr = p.users;
      p.users.forEach((u) => {
        arr.push({ id: u });
      });
    }
  });
  return arr;
});
watchEffect(() => {
  if (
    success.value.length + failure.value.length == excelData.value.length &&
    success.value.length
  ) {
    excelData.value = [];
  }
});
const forgetFile = () => {
  excelData.value = [];
};
const importData = () => {
  // console.log(excelData.value);
  excelData.value.slice(1).forEach((d) => {
    if (!checkIfLeadExists(String(d[1]))) {
      //var leadObj = { name: d[0], phone: d[1] };
      const leadObj = {
        admin: store.admin,
        email: d[2],
        updated: serverTimestamp(),
        created: serverTimestamp(),
        name: d[0],
        phone: [d[1]],
        priority: false, //priority of lead
        stages: selectedStage.value,
        projects: selectedProject.value,
        comments: [d[3]], //comments made by users
        reminders: [], //
        activitylog: [], //record of all activities done on this lead
        //users: randomUser, //which users can access this
        users: [fetchUser()], //this is done so that one lead can be shared across multiple users
        source: [d[4]], //where did this lead come from - reference/magicbricks/olx etc
        tags: [], //searchable and sortable tags
        type: ["buyer"], //buyer/seller
        interested: "yes", //yes, no, maybe
      };
      success.value.push(leadObj);
    } else {
      var errObj = {
        error: "Error!" + d[0] + " " + d[1] + " already exists!",
      };
      failure.value.push(errObj);
    }
  });
  excelData.value = [];
  selectedProject.value = "";
  selectedStage.value = "";
  selectedUser.value = "";
};
const fetchUser = () => {
  var id = "";
  if (selectedUser.value == "random") {
    id =
      sortedUsers.value[Math.floor(Math.random() * sortedUsers.value.length)]
        .id;
  } else {
    id = selectedUser.value;
  }
  return id;
};
const sortedStages = computed(() => {
  const arr = [];
  let next_id;
  const first = stages.value.filter((st) => st.next == "end");
  if (first[0]) {
    //find last stage
    arr.unshift(first[0]);
    next_id = first[0].id;
  }
  for (let i = 0; i < stages.value.length; i++) {
    stages.value.forEach((as) => {
      if (as.next == next_id && !arr.includes(as)) {
        arr.unshift(as);
        next_id = as.id;
      }
    });
  }
  return arr;
});
const selectProject = async (id) => {
  selectedUser.value = "";
  selectedStage.value = "";
  excelData.value = [];
  if (selectedProject.value == id) {
    selectedProject.value = "";
  } else {
    selectedProject.value = id;
  }
  if (selectedProject.value) {
    //console.log("fetch leads for: ", selectedProject.value);
    const q = query(
      collection(db, "leads"),
      where("projects", "==", selectedProject.value)
    );
    projectLeads.value = []; //initilize projectLeads array
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((docy) => {
      projectLeads.value.push({ id: docy.id, ...docy.data() });
    });
  }
};
const selectStage = (id) => {
  if (selectedStage.value == id) {
    selectedStage.value = "";
  } else {
    selectedStage.value = id;
  }
};
const selectUser = (id) => {
  if (selectedUser.value == id) {
    selectedUser.value = "";
  } else {
    selectedUser.value = id;
  }
};
const getUserName = () => {
  var name = "";
  users.value.forEach((p) => {
    if (p.id == selectedUser.value) {
      name = p.name;
    }
  });
  if (!name) name = "Random";
  return name;
};
const getStageName = () => {
  var name = "";
  stages.value.forEach((p) => {
    if (p.id == selectedStage.value) {
      name = p.name;
    }
  });
  return name;
};
const getProjectName = () => {
  var name = "";
  projects.value.forEach((p) => {
    if (p.id == selectedProject.value) {
      name = p.name;
    }
  });
  return name;
};
const handleFileUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // Assuming the first sheet is the one you want to display
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Convert the sheet data to an array of objects
      const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      excelData.value = sheetData;
    };
    reader.readAsArrayBuffer(file);
  }
};
const removeFromUpload = (row) => {
  excelData.value = excelData.value.filter((item) => item != row);
  if (excelData.value.length == 1) {
    excelData.value = [];
  }
};
const checkIfLeadExists = (str) => {
  var found = false;
  str = str.replace(/\D/g, ""); //remove all non numeric characters from phone
  if (str.length >= 10) {
    str = str.slice(-10); //get only the last 10 digits
  }
  projectLeads.value.forEach((l) => {
    l.phone.forEach((p) => {
      if (p.slice(-10) == str) {
        found = true;
      }
    });
  });
  var count = 0;
  excelData.value.forEach((l) => {
    //to check if a phone number exists twice in import excel file
    if (l[1] == str) {
      count++;
    }
  });
  if (count > 1) {
    found = true;
  }
  return found;
};
onMounted(async () => {
  console.log("mounted here");
  const u = query(
    collection(db, "users"),
    where("admin", "==", store.admin),
    where("active", "==", true)
  );
  const s = query(collection(db, "stages"), where("admin", "==", store.admin));
  const p = query(
    collection(db, "projects"),
    where("admin", "==", store.admin)
  );
  const usersSnapshot = await getDocs(u);
  usersSnapshot.forEach((docy) => {
    if (docy.id != store.admin) {
      users.value.push({ id: docy.id, ...docy.data() });
    }
  });
  const stagesSnapshot = await getDocs(s);
  stagesSnapshot.forEach((docy) => {
    stages.value.push({ id: docy.id, ...docy.data() });
  });
  const projectsSnapshot = await getDocs(p);
  projectsSnapshot.forEach((docy) => {
    projects.value.push({ id: docy.id, ...docy.data() });
  });
});
// const dummyLead = async () => {
//   const leadObj = {
//     admin: store.admin,
//     email: faker.internet.exampleEmail(),
//     updated: serverTimestamp(),
//     created: serverTimestamp(),
//     name: faker.person.fullName(),
//     phone: [nanoid()],
//     priority: false, //priority of lead
//     stages: randomStage,
//     projects: randomProject,
//     comments: [], //comments made by users
//     reminders: [], //
//     activitylog: [], //record of all activities done on this lead
//     //users: randomUser, //which users can access this
//     users: ["iB9eDctzicREnMaWApKqkVY2VtI2"], //this is done so that one lead can be shared across multiple users
//     source: [], //where did this lead come from - reference/magicbricks/olx etc
//     tags: [], //searchable and sortable tags
//     type: ["buyer"], //buyer/seller
//     interested: "yes", //yes, no, maybe
//   };
//   //console.log(leadObj);
//   const docRef = await addDoc(collection(db, "leads"), leadObj);
//   console.log("Document written with ID: ", docRef.id);
// };
</script>
<style scoped>
.table {
  font-size: 8px;
}
</style>
