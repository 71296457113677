// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB7qSMgyXvNIehRecwrHFk92DLqIMfDVpo",
  authDomain: "estates-b358a.firebaseapp.com",
  projectId: "estates-b358a",
  storageBucket: "estates-b358a.appspot.com",
  messagingSenderId: "1023182653640",
  appId: "1:1023182653640:web:b5a8ad9eb6881384d57dfd",
};

// Initialize Firebase
//const app = initializeApp(firebaseConfig);
initializeApp(firebaseConfig);
const db = getFirestore();
const auth = getAuth();

export { db, auth };
