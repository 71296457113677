<template>
  <div class="row">
    <div class="col">
      <div class="row">
        <div class="col">
          <input type="text" class="form-control" v-model="note" />
        </div>
        <div class="col">
          <button class="btn btn-primary btn-sm mt-1" @click="addNote()">
            Add
          </button>
        </div>
      </div>
      <ul class="list-group" v-if="user.settings">
        <li class="list-group-item" v-for="n in user.settings.notes" :key="n">
          {{ n }}<i class="fa fa-trash-o float-end" @click="deleteNote(n)"></i>
        </li>
      </ul>
    </div>
    <div class="col"></div>
    <div class="col">Col1</div>
  </div>
</template>
<script setup>
import { useStore } from "../../store/index";
import { db, auth } from "../../firebase/config";
import {
  doc,
  onSnapshot,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { onBeforeUnmount, onMounted, ref } from "vue";
const user = ref({});
const note = ref("");
var unsub = () => {};
onMounted(() => {
  unsub = onSnapshot(doc(db, "users", store.userid), (doc) => {
    //console.log("Current data: ", doc.data());
    user.value = { id: doc.id, ...doc.data() };
  });
});
onBeforeUnmount(() => {
  unsub();
});
const store = useStore();
const addNote = async () => {
  const washingtonRef = doc(db, "users", store.userid);
  await updateDoc(washingtonRef, {
    "settings.notes": arrayUnion(note.value),
  });
  note.value = "";
};
const deleteNote = async (n) => {
  const washingtonRef = doc(db, "users", store.userid);
  await updateDoc(washingtonRef, {
    "settings.notes": arrayRemove(n),
  });
};
</script>
