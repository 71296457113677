<template>
  <div class="container">
    <div class="row border rounded">
      <div class="col">
        <button class="btn btn-sm btn-primary" @click="toggleShowNew()">
          New
        </button>
      </div>
      <div class="col" v-if="showNew">
        <input
          type="text"
          class="form-control"
          placeholder="Collection name..."
          v-model.trim="projectName"
        />
      </div>
      <div class="col" v-if="showNew">
        <textarea
          class="form-control"
          placeholder="Description..."
          rows="2"
          v-model.trim="projectDescription"
        ></textarea>
      </div>

      <div class="col" v-if="showNew">
        <button class="btn btn-primary btn-sm" @click="addProject()">
          Add
        </button>
      </div>
    </div>
    <div class="row border rounded stdrow" v-for="p in allProjects" :key="p.id">
      <div class="col">{{ p.name }}</div>
      <div class="col">{{ p.description }}</div>
      <div class="col">
        <i class="bi bi-rocket-takeoff" v-if="p.boost"></i>
      </div>
      <div class="col">
        <button
          class="btn btn-primary btn-sm editbtn float-end"
          @click="selectProject(p.id)"
        >
          Edit
        </button>
      </div>

      <div class="row" v-if="p.id == store.editproject">
        <EditProject></EditProject>
      </div>
    </div>
  </div>
  <!-- <button class="btn btn-primary" @click="dummyLead()">
    Generate Dummy Lead
  </button> -->
</template>
<script setup>
import { onBeforeUnmount, onMounted, ref } from "vue";
import {
  collection,
  addDoc,
  serverTimestamp,
  query,
  where,
  onSnapshot,
  getDocs,
} from "firebase/firestore";
import { useStore } from "../../store/index";
import { db } from "../../firebase/config";
import { faker } from "@faker-js/faker/locale/en_IN";
const { customAlphabet } = require("nanoid");
import EditProject from "../projects/EditProject";

const alphabet = "123456789";
const nanoid = customAlphabet(alphabet, 10);

const store = useStore();
const projectName = ref("");
const projectDescription = ref("");
const allProjects = ref([]);
const allStages = ref([]);
const showNew = ref(false);
let unsub;
const toggleShowNew = () => {
  showNew.value = !showNew.value;
};
onBeforeUnmount(() => {
  unsub();
});
onMounted(async () => {
  const q = query(
    collection(db, "projects"),
    where("admin", "==", store.admin)
  );
  unsub = onSnapshot(q, (querySnapshot) => {
    const cities = [];
    querySnapshot.forEach((doc) => {
      cities.push({ id: doc.id, ...doc.data() });
    });
    allProjects.value = cities.sort((a, b) => {
      return b.boost - a.boost; //this is done to sort projects as per boost
    });
  });
  const k = query(collection(db, "stages"), where("admin", "==", store.admin));

  const querySnapshot = await getDocs(k);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    allStages.value.push(doc.id);
    console.log(doc.id, " stages => ", doc.data());
  });
});
// const boost = ref(false);

const dummyLead = async () => {
  const randomProject = getRandomProject();
  const randomStage = getRandomStage();
  const randomUser = getRandomUser();
  const leadObj = {
    admin: store.admin,
    email: faker.internet.exampleEmail(),
    updated: serverTimestamp(),
    created: serverTimestamp(),
    name: faker.person.fullName(),
    phone: [nanoid()],
    priority: false, //priority of lead
    //stages: { ["projectID_" + randomProject]: randomStage }, // where in the sales process (stage) is he
    //projects: [randomProject], //which properties/projects is this lead interested in - can be blank too
    stages: randomStage,
    projects: randomProject,
    comments: [], //comments made by users
    reminders: [], //
    activitylog: [], //record of all activities done on this lead
    //users: randomUser, //which users can access this
    users: ["iB9eDctzicREnMaWApKqkVY2VtI2"], //this is done so that one lead can be shared across multiple users
    source: [], //where did this lead come from - reference/magicbricks/olx etc
    tags: [], //searchable and sortable tags
    type: ["buyer"], //buyer/seller
    interested: "yes", //yes, no, maybe
  };
  //console.log(leadObj);
  const docRef = await addDoc(collection(db, "leads"), leadObj);
  console.log("Document written with ID: ", docRef.id);
};
const selectProject = (id) => {
  if (store.editproject != id) {
    store.setEditProject(id);
  } else {
    store.clearEditProject();
  }
};
const addProject = async () => {
  const projObj = {
    admin: store.admin,
    classification: "residential",
    description: projectDescription.value,
    name: projectName.value,
    boost: false,
    users: [],
  };
  if (projectDescription.value != "" && projectName.value != "") {
    const docRef = await addDoc(collection(db, "projects"), projObj);
    console.log("Document written with ID: ", docRef.id);
    projectDescription.value = "";
    projectName.value = "";
  }
};
const getRandomProject = () => {
  const random = Math.floor(Math.random() * allProjects.value.length);
  let random_id = allProjects.value[random].id;
  console.log("project=>", random_id);
  return random_id;
};
const getRandomStage = () => {
  const random = Math.floor(Math.random() * allStages.value.length);
  let random_id = allStages.value[random];
  console.log("stage=>", random_id);
  return random_id;
};
const getRandomUser = () => {
  const users = [
    "iB9eDctzicREnMaWApKqkVY2VtI2",
    "aoZTHDZHSDO1U1EDRfvFDDy9Mwt2",
  ];
  const random = Math.floor(Math.random() * users.length);
  let random_id = users[random];
  //console.log("stage=>", random_id);
  return random_id;
};
</script>
<style scoped>
.stdrow:hover {
  background-color: #c1e1c1;
  transition: 1s;
}
.stdrow:hover .editbtn {
  opacity: 1;
  transition: 0.5s;
}
.editbtn {
  opacity: 0;
}
.stdrow {
  font-size: 12px;
}
.bi-rocket-takeoff {
  font-size: 18px;
}
</style>
