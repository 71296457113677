<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" @click="toMain()">Projects</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Stages
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li class="dropdown-item" @click="toStages()">Edit</li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Settings
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li class="dropdown-item" @click="toSettings()">Config</li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Leads
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li class="dropdown-item" @click="toImport()">Import</li>
              <li class="dropdown-item">Assign</li>
            </ul>
          </li>
          <!-- <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Permissions
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li class="dropdown-item" @click="toPermissions()">Set/Revoke</li>
            </ul>
          </li> -->
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Users
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li class="dropdown-item" @click="toUser()">New</li>
              <li class="dropdown-item">Edit</li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Reports
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li class="dropdown-item" @click="toActivity()">Activity</li>
              <li class="dropdown-item" @click="toStatusReport()">Status</li>
              <li class="dropdown-item" @click="toUserReport()">Users</li>
            </ul>
          </li>
        </ul>
        <!-- <form class="border d-flex">
          <button class="btn btn-sm btn-outline-secondary" @click="logout()">
            Logout
          </button>
        </form> -->
        <button class="btn btn-sm btn-outline-secondary" @click="logout()">
          Logout
        </button>
      </div>
      <!-- ///////////////////////////////////// -->
    </div>
  </nav>
</template>
<script setup>
import { signOut } from "firebase/auth";
import { onMounted, onBeforeUnmount, ref } from "vue";
import { auth, db } from "../../firebase/config";
import { useRouter } from "vue-router";
import { useStore } from "../../store/index";
import { collection, query, where, onSnapshot } from "firebase/firestore";
const store = useStore();
const router = useRouter();
let unsub;
//const allSettings = ref([]);
onMounted(() => {
  const k = query(
    collection(db, "settings"),
    where("admin", "==", store.admin)
  );
  unsub = onSnapshot(k, (querySnapshot) => {
    const cities = [];
    querySnapshot.forEach((docy) => {
      cities.push({ id: docy.id, ...docy.data() });
    });
    store.setSettings(cities[0]);
  });
});
onBeforeUnmount(() => {
  unsub();
});
const logout = () => {
  signOut(auth)
    .then(() => {
      // Sign-out successful.
      store.clearUser();
      router.push("/");
      //console.log("logging out and moving to /");
      //router.push({ path: "/" });
    })
    .catch((error) => {
      // An error happened.
    });
};
const toStages = () => {
  router.push("/stages");
};
const toMain = () => {
  router.push("/main");
};
const toSettings = () => {
  router.push("/settings");
};
const toImport = () => {
  router.push("/import");
};
const toPermissions = () => {
  router.push("/permissions");
};
const toUser = () => {
  router.push("/user");
};
</script>
