<template>
  <div class="container">
    <ManagerNav v-if="store.type == 'admin'"></ManagerNav>
    <div class="row">
      <div class="col">
        <NewUser></NewUser>
      </div>
      <div class="col">
        <ManageUsers></ManageUsers>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useStore } from "../store/index";
import ManagerNav from "../components/navbars/ManagerNav.vue";
import NewUser from "./../components/users/NewUser";
import ManageUsers from "./../components/users/ManageUsers";
const store = useStore();
</script>
