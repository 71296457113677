<template>
  <div class="container">
    <ManagerNav v-if="store.type == 'admin'"></ManagerNav>
    <StagesHome></StagesHome>
    <!-- <BookingButton v-if="store.type == 'user'"></BookingButton> -->
  </div>
</template>
<script setup>
import { useStore } from "../store/index";
import ManagerNav from "../components/navbars/ManagerNav.vue";
import StagesHome from "../components/stages/StagesHome.vue";
// import BookingButton from "@/components/home/BookingButton.vue";
const store = useStore();
</script>
