<template>
  <div class="row" v-if="!selectName">
    <div class="col" @click="toggleName()">
      {{ props.data.name }}
    </div>
  </div>
  <div class="row" v-if="selectName">
    <div class="col">
      <div class="input-group mb-1 mt-1 smalltxt">
        <input
          type="text"
          class="form-control reminder"
          v-model.trim="name"
          aria-describedby="button-addon2"
        />
        <button
          class="btn btn-outline-secondary reminder"
          type="button"
          id="button-addon2"
          @click="updateName()"
        >
          Update
        </button>
      </div>
    </div>
  </div>
  <div class="row" v-if="store.settings.showphone">
    <div class="col">
      <ul class="list-group">
        <li
          class="list-group-item nopadding"
          v-for="(ph, index) in props.data.phone"
          :key="ph"
        >
          <span @click="selectPhone(ph)">{{ ph }}</span>
          <i
            class="fa fa-plus float-end"
            v-if="index == props.data.phone.length - 1"
            @click="toggleAddPhone()"
          ></i>
          <i
            class="fa fa-trash-o float-end mr-2"
            @click="removePhone(ph)"
            v-if="deletePhone == ph"
          ></i>
        </li>
        <li class="list-group-item nopadding" v-if="newPhone">
          <div class="input-group mb-1 mt-1 smalltxt">
            <input
              type="text"
              class="form-control reminder"
              v-model.trim="phone"
              aria-describedby="button-addon3"
            />
            <button
              class="btn btn-outline-secondary reminder"
              type="button"
              id="button-addon3"
              @click="addPhone()"
            >
              Add
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps } from "vue";
import { db } from "../../firebase/config";
import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { useStore } from "../../store/index";
const store = useStore();
const props = defineProps(["data"]);
const selectName = ref(false);
const name = ref("");
const phone = ref("");
const deletePhone = ref("");
const newPhone = ref(false);
const toggleAddPhone = () => {
  newPhone.value = !newPhone.value;
};
const selectPhone = (ph) => {
  if (deletePhone.value != ph) {
    deletePhone.value = ph;
  } else {
    deletePhone.value = "";
  }
};
const toggleName = () => {
  selectName.value = !selectName.value;
  name.value = props.data.name;
};
const updateName = async () => {
  console.log(name.value);
  const leadRef = doc(db, "leads", props.data.id);
  await updateDoc(leadRef, {
    name: name.value,
  });

  toggleName();
};
const addPhone = async () => {
  if (phone.value.length) {
    const leadRef = doc(db, "leads", props.data.id);
    await updateDoc(leadRef, {
      phone: arrayUnion(phone.value),
    });
  }

  phone.value = "";
  toggleAddPhone();
};
const removePhone = async (ph) => {
  const leadRef = doc(db, "leads", props.data.id);
  await updateDoc(leadRef, {
    phone: arrayRemove(ph),
  });
};
</script>
<style scoped>
.smalltxt {
  padding: 2px;
}
.reminder {
  padding: 1px;
  font-size: 10px;
}
.nopadding {
  padding: 2px;
}
</style>
