<template>
  <div class="container">
    <div class="card mx-auto mt-5" style="width: 20rem">
      <div class="card-body">
        <h5 class="card-title text-center">PrimeLeads!</h5>
        <h6 class="card-subtitle mb-2 text-muted text-center">
          Contact & follow-up
        </h6>
        <div class="card-text">
          <div class="row mt-1">
            <input
              type="text"
              class="form-control col m-1"
              placeholder="Email"
              v-model.trim="email"
            />
          </div>
          <div class="row mt-1">
            <input
              type="password"
              class="form-control col m-1"
              placeholder="Password"
              v-model.trim="password"
            />
          </div>
          <div class="row mt-1">
            <button class="btn btn-primary col m-1" @click="login()">
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { db, auth } from "../../firebase/config";
import { useRouter } from "vue-router";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useStore } from "../../store/index";
import { doc, getDoc, query, collection, where } from "firebase/firestore";
const router = useRouter();
const email = ref("");
const password = ref("");
const store = useStore();
const fetchUserDetails = (email) => {
  const q = query(collection(db, "users"), where("email", "==", email));
};
const login = () => {
  //console.log(email.value, password.value);
  signInWithEmailAndPassword(auth, email.value, password.value)
    .then(async (userCredential) => {
      // Signed in
      const user = userCredential.user;
      console.log("Loginform=>", user.email);
      fetchUserDetails(user.email);
      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        store.setUser({ userid: user.uid, admin: data.admin, type: data.type });
        router.push("/main");
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }

      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
    });
};
</script>
