<template>
  <div class="row" v-if="error">
    {{ error }}
  </div>
  <div class="row">
    <div class="col">
      <div class="card" style="width: 18rem">
        <div class="card-body">
          <h5 class="card-title">New user</h5>
          <div class="row">
            <input
              type="text"
              class="form-control"
              placeholder="Email"
              v-model="email"
            />
          </div>
          <div class="row">
            <input
              type="text"
              class="form-control mt-1"
              placeholder="Name"
              v-model="name"
            />
          </div>
          <button class="btn btn-primary mt-1 float-end" @click="addUser()">
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase/config";
import { useStore } from "../../store/index";
const store = useStore();
const email = ref("");
const error = ref("");
const name = ref("");
const isEmailUnique = async (email) => {
  var unique = true;
  const q = query(collection(db, "users"), where("email", "==", email));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((docy) => {
    if (docy.data().email == email) {
      unique = false;
    }
  });
  return unique;
};
const addUser = async () => {
  if (email.value && name.value) {
    //console.log(email.value, name.value);
    const userObj = {
      email: email.value,
      name: name.value,
      admin: store.admin,
      active: true,
      type: "user",
      userid: "",
      addnewlead: false,
    };
    //console.log(userObj);
    if (await isEmailUnique(email.value)) {
      await addDoc(collection(db, "users"), userObj);
    } else {
      error.value = "This email id already exists!!";
    }
  }

  email.value = "";
  name.value = "";
};
</script>
